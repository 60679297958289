import React, { useEffect } from "react";
import { useField } from "@formiz/core";
import { Stack, Input, Text } from "@chakra-ui/react";
import dayjs from "dayjs";

export const FieldDatePicker = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    // value
  } = useField(props);
  const { label, type, required, mb, defaultValue, size, ...rest } = props;
  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  if (defaultValue) {
    var dateFormattedForDefaultValue = dayjs(defaultValue, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
  }

  useEffect(() => {
    setValue(dateFormattedForDefaultValue);
  }, [defaultValue, dateFormattedForDefaultValue, setValue]);

  return (
    <Stack mb={mb} spacing={0} {...rest}>
      <label htmlFor={id}>
        <Text fontSize={size ?? 11} color={"gray.500"}>
          {label}
          {!!required && " *"}
        </Text>
      </label>
      <Input
        id={id}
        type="date"
        defaultValue={dateFormattedForDefaultValue}
        // value={value ?? ""}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setIsTouched(true)}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
        borderColor={showError ? "red" : null}
        size={size ?? "sm"}
      />
      {showError && (
        <Text color="red" fontWeight="500" fontSize="13px" id={`${id}-error`}>
          {errorMessage}
        </Text>
      )}
    </Stack>
  );
};
