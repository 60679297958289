const age = [
  { value: "1824", display: "18-24" },
  { value: "2529", display: "25-29" },
  { value: "3039", display: "30-39" },
  { value: "4049", display: "40-49" },
  { value: "5059", display: "50-59" },
  { value: "60", display: "60+" },
];

const genre = [
  { value: "fille", display: "Fille" },
  { value: "garcon", display: "Garçon" },
];
const typeResponsable = [
  { value: "pere", display: "Père" },
  { value: "mere", display: "Mère" },
  { value: "autre", display: "Autre" },
];

const experience = [
  { value: "oui", display: "Oui" },
  { value: "non", display: "Non" },
];

const categorieProduits = [
  { value: "fashion", display: "Fashion et Accessoires" },
  { value: "fitness", display: "Fitness" },
  { value: "alimentation", display: "Alimentation" },
  { value: "soin_cheveux", display: "Soin Cheveux" },
  { value: "bijoux", display: "Bijoux" },
  { value: "maquillage", display: "Maquillage" },
  { value: "maman", display: "Maman & Produits Bébé" },
  { value: "cours", display: "Cours en ligne" },
  { value: "animaux", display: "Animaux" },
  { value: "spins_peau", display: "Soins pour la peau" },
  { value: "bienetre", display: "Bien-être" },
  { value: "voyage", display: "Voyage" },
  { value: "maison", display: "Maison et Décoration" },
  { value: "gaming", display: "Gaming" },
  { value: "lifestyle", display: "Lifestyle" },
  { value: "autres", display: "Autres" },
];

const enfants = [
  { value: "pas_enfants", display: "Pas d'enfants" },
  { value: "en_route", display: "🤰 En route" },
  { value: "un_enfant", display: "Un enfant" },
  { value: "plus_un_enfant", display: "Plus d'un enfant" },
  { value: "jumeaux", display: "Jumeaux" },
  { value: "triples", display: "Triplés" },
];

const animaux = [
  { value: "un_chat", display: "Un chat" },
  { value: "plus_un_chat", display: "Plus d'un chat" },
  { value: "un_chien", display: "Un chien" },
  { value: "plus_un_chien", display: "Plus d'un chien" },
  { value: "autres", display: "Autres" },
];

const plateformes = [
  { value: "facebook", display: "Facebook" },
  { value: "instagram", display: "Instagram" },
  { value: "tiktok", display: "Tiktok" },
  { value: "youtube", display: "Youtube" },
  { value: "snapchat", display: "Snapchat" },
];

const moyensContact = [
  { value: "email", display: "Email" },
  { value: "telephone", display: "Téléphone" },
];

const typeInscription = [
  { value: "inscription", display: "Inscription" },
  { value: "reinscription", display: "Réinscription" },
];

export const getQuestions = () => {
  return {
    age,
    genre,
    experience,
    categorieProduits,
    enfants,
    animaux,
    typeResponsable,
    plateformes,
    moyensContact,
    typeInscription
  };
};
