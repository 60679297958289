import { useToast } from "components/ui/use-toast";
import Axios from "config/axios";
import { useMutation } from "react-query";

/**
 * @author Issa Drici <issadricipro@gmail.com>
 * @returns
 */
export const useDeleteEleveClasse = ({ handleCallbackSuccess }) => {
  // Hooks

  const { toast } = useToast();

  const { mutate, isLoading, isError } = useMutation(
    (eleveClasseId) => Axios.delete(`/eleve-classe/${eleveClasseId}`),
    {
      onSuccess: async () => {
        // toastSuccess(toast, { title: "Appel enregistré !" });
        if (handleCallbackSuccess !== undefined) {
          handleCallbackSuccess();
        }
        return true;
      },
      onError: async (err) => {
        toast({
          variant: "destructive",
          title: "Oh là là ! Quelque chose s'est mal passé.",
          description: "Erreur de suppression",
        })
        return false;
      },
    }
  );

  return { mutate, isLoading, isError };
};
