import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindInscriptionByFilters = (params) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    ["inscription", params?.toString()],
    () => {
      return Axios.get(`/inscription`, { params });
    },
    { retry: false, refetchOnWindowFocus: true,}
  );

  const { inscription } = data ?? [];

  return {
    inscription,
    isLoading: isLoading || isFetching,
    isError,
  };
};
