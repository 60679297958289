import {
  Avatar,
  HStack,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { useFindAllAnneesScolaire } from "App/annee-scolaire/services/useFindAllAnneesScolaire";
import { useFindAllClasses } from "App/classe/services/useFindAllClasses";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useCreateEleveClasse } from "../services/useCreateEleveClasse";
import FormulaireEleveClasse from "./FormulaireEleveClasse";

export default function ModalCreateClasseEleveClasse(props) {
  const { eleve, eleveId, isOpen, onClose } = props;
  
  const queryClient = useQueryClient();
  const formEleveClasse = useForm({ subscribe: "form" });

  const [classeId, setClasseId] = useState();
  const [anneeScolaireId, setAnneeScolaireId] = useState();

  const { classes, isLoading: isLoadingClasses } = useFindAllClasses();
  const { anneesScolaire, isLoading: isLoadingAnneesScolaire } =
    useFindAllAnneesScolaire();

  const handleCallbackSuccess = async () => {
    await queryClient.invalidateQueries("elevesClasse-eleve", eleveId);
    onClose();
  };

  const { mutate: createEleveClasse, isLoading: isLoadingCreateEleveClasse } = useCreateEleveClasse({
    handleCallbackSuccess,
  });

  const handleSubmit = (values) => {
    const data = {
      data: {
        ...values,
        eleve: eleve?.id ?? null,
        classe: classeId ?? null,
        anneeScolaire: anneeScolaireId ?? null,
      },
    };

    createEleveClasse(data);
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <Formiz autoform connect={formEleveClasse} onValidSubmit={handleSubmit}>
          <ModalHeader>Ajouter à une classe</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              key={eleve?.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgColor="green.100"
              p={2}
              borderRadius={10}
              shadow="base"
              mb={4}
            >
              <HStack>
                <Avatar size="sm" name={`${eleve?.nom} ${eleve?.prenom}`} />
                <Text>{`${eleve?.nom} ${eleve?.prenom}`}</Text>
              </HStack>
            </Stack>
            {!isLoadingClasses && !isLoadingAnneesScolaire && (
              <FormulaireEleveClasse
                classes={classes}
                anneesScolaire={anneesScolaire}
                setClasseId={setClasseId}
                setAnneeScolaireId={setAnneeScolaireId}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="green" onClick={formEleveClasse.submit} isLoading={isLoadingCreateEleveClasse}>
              Enregistrer
            </Button>
          </ModalFooter>
        </Formiz>
      </ModalContent>
    </Modal>
  );
}
