import Axios from "config/axios";
import { useMutation } from "react-query";
import { useToast } from "components/ui/use-toast";
import { toastError } from "functions/toast";

/**
 * @author Issa Drici <issadrici@gmail.com>
 * @returns
 */
export const useCreateEleveClasse = ({ handleCallbackSuccess }) => {
  const { toast } = useToast();

  return useMutation(({ data }) => Axios.post(`/eleve-classe`, data), {
    onSuccess: async () => {
      if (handleCallbackSuccess !== undefined) {
        handleCallbackSuccess();
      }
      return true;
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Oh là là ! Quelque chose s'est mal passé.",
        description: "Erreur de création",
      })
      return false;
    },
  });
};
