import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";

const BadgeCours = (props) => {
  const { cours } = props;
  return (
    <Box
      paddingX={3}
      paddingY={2}
      borderRadius={7}
      backgroundColor="#8bc34a"
      shadow="md"
    >
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Text
          textTransform="uppercase"
          fontWeight={700}
          fontSize={11}
          color="#FFF"
        >
          {cours?.classe?.etablissement?.nom === "CE"
            ? "Centre Essalam"
            : cours?.classe?.etablissement?.nom}
        </Text>
        <Text
          textTransform="uppercase"
          fontWeight={700}
          fontSize={11}
          color="#FFF"
        >
          {`${cours?.jour} ${cours?.heure}`}
        </Text>
      </Stack>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Text fontWeight={700} color="#FFF">
          {`${cours?.professeur?.utilisateur?.prenom}.${cours?.professeur?.utilisateur?.nom[0]}`}
        </Text>

        <Text textTransform="uppercase" fontWeight={700} color="#FFF">
          {cours?.classe?.nom}
        </Text>
      </Stack>
    </Box>
  );
};

export default BadgeCours;
