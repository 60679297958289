import { Stack } from "@chakra-ui/react";
import { ReactSelect } from "components/react-select/ReactSelect";
import React from "react";

const FormulaireEleveClasse = (props) => {
  const {
    classes,
    anneesScolaire,
    classeEleveId,
    setClasseId,
    setAnneeScolaireId,
  } = props;

  const classeCE = classes?.CE?.map((item) => ({
    value: item?.id,
    label: `${item?.etablissement?.nom} - ${item?.nom}`,
  }));

  const classeCIH = classes?.CIH?.map((item) => ({
    value: item?.id,
    label: `${item?.etablissement?.nom} - ${item?.nom}`,
  }));

  const optionsClasses = [...classeCE, ...classeCIH];
  const optionsAnneesScolaire = anneesScolaire?.map((annee) => ({
    value: annee?.id,
    label: annee?.label,
  }));

  return (
    <Stack>
      <ReactSelect
        callback={(e) => setClasseId(e?.value)}
        defaultValue={
          classeEleveId
            ? optionsClasses?.filter(
                (item) => item?.value === parseInt(classeEleveId)
              )
            : null
        }
        name="classe"
        label="Classe"
        className="basic-single"
        placeholder="Sélectionner..."
        options={optionsClasses}
        isClearable
      />
      {setAnneeScolaireId && (
        <ReactSelect
          callback={(e) => setAnneeScolaireId(e?.value)}
          defaultValue={
            classeEleveId
              ? optionsClasses?.filter(
                  (item) => item?.value === parseInt(classeEleveId)
                )
              : null
          }
          name="anneeScolaire"
          label="Année Scolaire"
          className="basic-single"
          placeholder="Sélectionner..."
          options={optionsAnneesScolaire}
          isClearable
        />
      )}
    </Stack>
  );
};

export default FormulaireEleveClasse;
