import Axios from "config/axios";
import { useMutation } from "react-query";
import { useToast } from "components/ui/use-toast";

/**
 * @author Issa Drici <issadrici@gmail.com>
 * @returns
 */
export const useCreateProfesseur = ({ handleCallbackSuccess }) => {
  const { toast } = useToast();

  return useMutation((data) => Axios.post(`/utilisateur/professeur`, data), {
    onSuccess: async (profCreated) => {
      if (handleCallbackSuccess !== undefined) {
        handleCallbackSuccess(profCreated);
      }
      return true;
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Oh là là ! Quelque chose s'est mal passé.",
        description: "Erreur de création",
      });
      return false;
    },
  });
};
