// import Axios from "axios";

// const instance = Axios.create({
//   baseURL: `${process.env.HOST_SKAPP_API}/`,
// });

// instance.defaults.headers.post["Content-Type"] = "application/json";

// instance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");

//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
    
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// export default instance;



/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { getCurrentUser } from './firebase';
import dayjs from 'dayjs';

const instance = Axios.create({
  baseURL: `${process.env.REACT_APP_HOST_SKAPP_API}`,
});

instance.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error?.response?.request.response) {
      const errorOjb = JSON.parse(error?.response?.request.response);
      if (errorOjb.statusCode === 401) {
        if (errorOjb.error.type === 'UnAuthorizedException') {
          window.location.href = '/unauthorized';
        } else {
          alert('Impossible de se connecter');
          window.location.href = '/login';
        }
      }
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  async (config) => {

    const currentUser = getCurrentUser();
    if (currentUser !== null) {
      const tokenResult = await currentUser.getIdTokenResult(false);
      const tokenExpiration = new dayjs(tokenResult.expirationTime);
      const dateExpiration = new dayjs();
      await currentUser
        .getIdToken(tokenExpiration <= dateExpiration)
        .then((idToken) => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${idToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          };
        })
        .catch((error) => {
          config.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          };
        });
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
