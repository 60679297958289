import {
  Avatar,
  Button,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Icon,
  Skeleton,
} from "@chakra-ui/react";
import { useFindElevesByCoursId } from "App/eleve/services/useFindElevesByCoursId";
import { useCreatePresencesCours } from "App/presence-cours/services/useCreatePresencesCours";
import { useFindPresencesCoursByCoursIdAndDate } from "App/presence-cours/services/useFindPresencesCoursByCoursIdAndDate";
// import { useUpdatePresencesCours } from "App/presence-cours/services/useUpdatePresencesCours";
import dayjs from "dayjs";
import * as React from "react";
import {
  AiFillCheckCircle,
  AiFillCheckSquare,
  AiOutlineBorder,
} from "react-icons/ai";
import { useQueryClient } from "react-query";

export default function CheckboxList(props) {
  const { coursId } = props;
  const queryClient = useQueryClient();

  const [checked, setChecked] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { presencesCours, isLoading: isLoadingPresencesCours } =
    useFindPresencesCoursByCoursIdAndDate(
      coursId,
      dayjs().format("YYYY-MM-DD")
    );

  const {
    elevesClasse,
    isLoading: isLoadingEleves,
    // isFetching: isFetchingELeves,
  } = useFindElevesByCoursId(coursId);

  const updateForm = presencesCours?.length > 0;

  const handleCallbackSuccess = async () => {
    await queryClient.invalidateQueries(["elevesClasse-cours", coursId]);
    await queryClient.invalidateQueries(["presences-cours"]);
    onClose();
  };

  const { mutate: createPresencesCours, isLoading: isLoadingCreatePresenceCours } = useCreatePresencesCours({
    handleCallbackSuccess,
  });

  // const { mutate: updatePresencesCours } = useUpdatePresencesCours({
  //   coursId,
  //   handleCallbackSuccess,
  // });

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getElevesChecked = () => {
    if (checked?.length < 1) {
      return (
        <HStack
          backgroundColor="green"
          width="fit-content"
          px={2}
          py={1}
          borderRadius={10}
        >
          <Icon color="white" as={AiFillCheckCircle} />
          <Text fontWeight={700} color="white">
            Aucun élève absent
          </Text>
        </HStack>
      );
    }
    if (checked?.length === 1) {
      return (
        <HStack
          backgroundColor="red"
          width="fit-content"
          px={2}
          py={1}
          borderRadius={10}
          spacing={1}
        >
          <Text fontWeight={700} color="white">
            1
          </Text>
          <Text fontWeight={500} color="white">
            élève absent
          </Text>
        </HStack>
      );
    }
    return (
      <HStack
        backgroundColor="red"
        width="fit-content"
        px={2}
        py={1}
        borderRadius={10}
        spacing={1}
      >
        <Text fontWeight={700} color="white">
          {checked?.length}
        </Text>
        <Text fontWeight={500} color="white">
          élèves absents
        </Text>
      </HStack>
    );
  };

  const handleSubmit = () => {
    const dateOfAppel = dayjs().format("YYYY-MM-DD HH:mm:ss");

    const datas = {
      data: elevesClasse?.map((eleveClasse) => {
        const isInChecked = checked?.find(
          (check) => check.id === eleveClasse?.id
        );
        if (isInChecked) {
          return {
            coursId: coursId,
            eleveId: eleveClasse?.eleve?.id,
            isPresent: false,
            date: dateOfAppel,
          };
        }

        return {
          coursId: coursId,
          eleveId: eleveClasse?.eleve?.id,
          isPresent: true,
          date: dateOfAppel,
        };
      }),
    };

    // if (updateForm) {
    //   updatePresencesCours(datas);
    //   return null;
    // }

    // Create query
    createPresencesCours(datas);
    return null;
  };

  React.useEffect(() => {
    if (!isLoadingPresencesCours) {
      if (presencesCours?.length > 0) {
        var arrayCheckedDefault = presencesCours?.map((presence) => {
          if (presence?.is_present === false) {
            return elevesClasse?.find(
              (eleveClasse) => eleveClasse?.eleve?.id === presence?.eleve_id
            );
          }
          return undefined;
        });
        var checkedDefault = arrayCheckedDefault?.filter((element) => {
          return element !== undefined;
        });
        setChecked(checkedDefault);
      }
    }
  }, [elevesClasse, isLoadingPresencesCours, presencesCours]);

  return (
    <Stack spacing={5}>
      {isLoadingEleves &&
        [0, 1, 2, 3, 4].map((item) => <Skeleton key={item} height="30px" />)}

      {!isLoadingEleves && !isLoadingPresencesCours && (
        <>
          <List width="100%" spacing={1}>
            {elevesClasse?.map((value) => {
              return (
                <ListItem
                  key={value?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgColor="white"
                  onClick={updateForm ? null : handleToggle(value)}
                  _hover={{
                    backgroundColor: "gray.100",
                  }}
                  _active={{ backgroundColor: "gray.200" }}
                  p={2}
                  borderRadius={10}
                  cursor={updateForm ? "auto" : "pointer"}
                  shadow="base"
                >
                  <HStack>
                    <Avatar
                      size="sm"
                      name={`${value?.eleve?.nom} ${value?.eleve?.prenom}`}
                    />
                    <Text>{`${value?.eleve?.nom} ${value?.eleve?.prenom}`}</Text>
                  </HStack>
                  {checked?.indexOf(value) !== -1 ? (
                    <Icon w={6} height={6} color="red" as={AiFillCheckSquare} />
                  ) : (
                    <Icon
                      w={6}
                      height={6}
                      color="gray.200"
                      as={AiOutlineBorder}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
          <Button colorScheme="twitter" onClick={onOpen} disabled={updateForm}>
            Enregistrer
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} size="xs">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirmer l'appel</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {getElevesChecked()}
                <Stack mt={3}>
                  {checked?.map((value) => {
                    return (
                      <HStack>
                        <Avatar
                          size="sm"
                          name={`${value?.eleve?.nom} ${value?.eleve?.prenom}`}
                        />
                        <Text>{`${value?.eleve?.nom} ${value?.eleve?.prenom}`}</Text>
                      </HStack>
                    );
                  })}
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Annuler
                </Button>
                <Button colorScheme="green" onClick={handleSubmit} isLoading={isLoadingCreatePresenceCours}>
                  Enregistrer
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </Stack>
  );
}

// TODO Find pour defaultValue
// TODO Appel existe pour ce jour et ce cours ?
