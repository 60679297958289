import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return professeur
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindElevesByClasseId = (classeId) => {
  const { data, isLoading, isFetching } = useQuery(["elevesClasse-classe", classeId], () => {
    if (classeId) {
      return Axios.get(`/eleves/${classeId}/classe`);
    }
  },
  { retry: false, refetchOnWindowFocus: true });

  const elevesClasse = data?.elevesClasse;

  return {
    elevesClasse,
    isLoading,
    isFetching,
  };
};
