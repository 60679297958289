import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return professeur
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindClasseById = (classeId) => {
  const { data, isLoading, isFetching } = useQuery(
    ["classe", classeId],
    () => {
      if (classeId) {
        return Axios.get(`/classe/${classeId}`);
      }
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const { classe, canCud } = data ?? {};

  return {
    classe,
    canCud,
    isLoading,
    isFetching,
  };
};
