import {
  Accordion,
  Box,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import AccordionItem from "components/accordion/AccordionItem";
import { toastError } from "functions/toast";
import Card from "components/card/Card";
import React from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useHandleDownload } from "services/files/files";
import { useFindAllClasses } from "../services/useFindAllClasses";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useAuthentificationContext } from "context/auth-context";
import Button from "components/button/Button";
import { PageHeader } from "App/layout/PageHeader";
import {
  DownloadIcon,
  EyeOpenIcon,
  PlusIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { DataTable } from "components/ui/data-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import { Checkbox } from "components/ui/checkbox";
import { DataTableRowActions } from "components/ui/data-table-row-actions";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { cn } from "lib/utils";
import { MenubarShortcut } from "components/ui/menubar";

const PageListeClasse = () => {
  const { classes, isLoading: isLoadingClasses } = useFindAllClasses();
  const { isAdmin, isEnseignement } = useAuthentificationContext();

  const { toast } = useToast();

  const { push } = useHistory();

  const { download, isLoading: isLoadingExtract } = useHandleDownload();

  const handleExtract = useCallback(async (withNumbers) => {
    try {
      // Start loading extract
      await download(
        `${process.env.REACT_APP_HOST_SKAPP_API}/eleve-classe/pdf/download?with_numbers=${withNumbers}`
      );
    } catch (error) {
      toast({
        variant: "destructive",
        title: "app:core.errors.extract_title",
        description: error.response.data.detail,
      });
    }
  }, [download, toast]);

  const columns = [
    {
      accessorKey: "nom",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Nom" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <DataTableRowActions row={row}>
              <DropdownMenuItem
                onClick={() => push(`/classe/${row?.original?.id}`)}
              >
                Voir{" "}
                <MenubarShortcut>
                  <EyeOpenIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem>
                Supprimer{" "}
                <MenubarShortcut>
                  <TrashIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </DropdownMenuItem>
            </DataTableRowActions>
          </div>
        );
      },
    },
  ];

  return (
    <LayoutDefault
      isLoading={isLoadingClasses || isLoadingExtract}
      dataAvailable={!!classes}
    >
      <PageHeader
        title="Classes"
        buttons={
          <div className="flex gap-3">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  isLoading={isLoadingExtract}
                  variant="outline"
                >
                  <DownloadIcon className="mr-2 h-4 w-4" /> Télécharger
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuLabel>Numéro de tel</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => handleExtract('1')}>
                  Avec
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleExtract('0')}>
                  Sans
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button
            // onClick={onOpenModalCreateInscription}
            >
              <PlusIcon className="mr-2 h-4 w-4" /> Nouvelle classe
            </Button>
          </div>
        }
      />

      <div className="flex gap-x-4 max-h-full">
        {classes
          ? Object.entries(classes)?.map(([k, classe]) => {
              return (
                <div className="flex-1">
                  <Card classCard="relative h-full" classContent="pt-4">
                    <p className="absolute top-5 left-5 font-bold">{k}</p>
                    <DataTable columns={columns} data={classe} />
                  </Card>
                </div>
              );
            })
          : null}
      </div>

      {/* <Card>
        <HStack justify="space-between">
          <Heading size="md">Classes</Heading>
          {(isAdmin || isEnseignement) && (
            <Tooltip label="Exporter">
              <IconButton
                onClick={() => handleExtract()}
                aria-label="Extract"
                icon={<AiOutlineCloudDownload />}
                isLoading={isLoadingExtract}
              />
            </Tooltip>
          )}
        </HStack>
      </Card>
      <Stack spacing={5}>
        {isLoadingClasses &&
          [0, 1, 2, 3, 4].map((item) => <Skeleton key={item} height="30px" />)}

        {!isLoadingClasses && (
          <>
              <Accordion allowMultiple>
                {Object.entries(classes)?.map(([k, valueClasses]) => (
                  <AccordionItem
                    key={k}
                    label={k}
                    bgColor="green.500"
                    color="white"
                  >
                    <Stack>
                      {valueClasses?.map((value) => {
                        return (
                          <Box
                            key={value?.id}
                            paddingX={3}
                            paddingY={2}
                            borderRadius={7}
                            backgroundColor="#8bc34a"
                            shadow="md"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            onClick={() => push(`/classe/${value?.id}`)}
                            cursor="pointer"
                          >
                            <Stack flex={1} spacing={0}>
                              <Text
                                textTransform="uppercase"
                                fontWeight={700}
                                color="#FFF"
                              >
                                {value?.nom}
                              </Text>
                            </Stack>
                          </Box>
                        );
                      })}
                    </Stack>
                  </AccordionItem>
                ))}
              </Accordion>
          </>
        )}
      </Stack> */}
    </LayoutDefault>
  );
};

export default PageListeClasse;
