/**
 * Fichier pointant sur le firebase stating ou production
 * Le choix se fait dans le docker
 * Par d�faut c'est le contenu de test qui est pr�sent
 */

 let firebaseConfigEnv = {};
 switch (process.env.ENV) {
   default:
   case 'local':
   case 'staging':
   case 'demo':
   case 'production':
   case 'prod':
     firebaseConfigEnv = {
        apiKey: "AIzaSyDlKMz309suoHbsXjxcmARXEIZzCu25THQ",
        authDomain: "skapp-229a9.firebaseapp.com",
        projectId: "skapp-229a9",
        storageBucket: "skapp-229a9.appspot.com",
        messagingSenderId: "294136645013",
        appId: "1:294136645013:web:7b6aedfde953e140e7229c",
        measurementId: "G-MHDWQ184DM"
      };
     break;
 }
 
 export const firebaseConfig = firebaseConfigEnv;
 