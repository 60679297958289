import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Stack,
  HStack,
  Text,
  Radio,
  RadioGroup,
  Badge,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FieldInput } from "components/fields/FieldInput";
import { FieldDatePicker } from "components/fields/FieldDatePicker";
import { isEmail } from "@formiz/validations";
import GroupFieldsTitle from "components/fields/GroupFieldsTitle";
import { useInscriptionEleve } from "../services/useInscriptionEleve";
import { getQuestions } from "./QuestionsInscription";

export default function ModalCreateInscription(props) {
  const { isOpen, onClose } = props;
  const { push } = useHistory();

  const formInscriptionEleve = useForm({ subscribe: "form" });

  const [genreChecked, setGenreChecked] = useState();
  const [typeResponsable1Checked, setTypeResponsable1Checked] = useState();
  const [typeResponsable2Checked, setTypeResponsable2Checked] = useState();
  const [acceptChecked, setAcceptChecked] = useState();
  const [acceptImageChecked, setAcceptImageChecked] = useState();
  const [adresseIdentique, setAdresseIdentique] = useState(true);

  const { genre, typeResponsable } = getQuestions();

  const handleCallbackSuccess = async ({ inscription }) => {
    await onClose();
  };

  const { mutate: inscriptionEleve, isLoading: isLoadingInscriptionEleve } =
    useInscriptionEleve({
      handleCallbackSuccess,
    });

  const handleSubmit = (values) => {
    const datas = {
      ...values,
      eleve: {
        ...values.eleve,
        genre: genreChecked,
        hasAcceptImage: acceptImageChecked ? acceptImageChecked : false,
      },
      inscription: {
        ...values.inscription,
        typeInscription: "reinscription",
        hasAccept: acceptChecked,
      },
      responsables: {
        0: {
          ...values.responsables[0],
          genre: typeResponsable1Checked,
        },
        1: {
          ...values.responsables[1],
          genre: typeResponsable2Checked,
          adresse: adresseIdentique
            ? values.responsables[0].adresse
            : values.responsables[1].adresse,
          codePostal: adresseIdentique
            ? values.responsables[0].codePostal
            : values.responsables[1].codePostal,
          ville: adresseIdentique
            ? values.responsables[0].ville
            : values.responsables[1].ville,
        },
      },
    };

    const formData = new FormData();
    formData.append("datas", JSON.stringify(datas));

    if (
      genreChecked !== undefined &&
      typeResponsable1Checked !== undefined &&
      typeResponsable2Checked !== undefined &&
      acceptChecked === true
    ) {
      inscriptionEleve(formData);
    }
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="90vw" w="fit-content">
        <Formiz
          autoform
          connect={formInscriptionEleve}
          onValidSubmit={handleSubmit}
        >
          <ModalHeader fontSize={18} fontWeight={600}>
            Créer une inscription
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              {/* DÉBUT DU FORMULAIRE */}

              <GroupFieldsTitle title="Responsables Légaux" />

              {/* REPSONSABLE 1 */}
              <Stack bgColor="rgba(0,0,0,.01)" p={2} borderRadius={12}>
                <Text
                  textTransform="uppercase"
                  fontWeight={700}
                  fontSize={12}
                  color="#334155"
                >
                  Responsable n°1
                </Text>

                {/* TYPE REPONSABLE */}
                <Stack spacing={1}>
                  <Text fontSize={14} fontWeight={600} color="#334155">
                    Choisir{" "}
                    <Badge
                      colorScheme="brand"
                      ml={1}
                      px={2}
                      py="2px"
                      borderRadius="6px"
                      textTransform="lowercase"
                    >
                      Requis
                    </Badge>
                  </Text>
                  <RadioGroup name="responsables.0.genre">
                    <HStack spacing={2}>
                      {typeResponsable?.map((item) => (
                        <Radio
                          key={item?.value}
                          value={item?.value}
                          onChange={(i) =>
                            setTypeResponsable1Checked(i?.target?.value)
                          }
                        >
                          <Text
                            textTransform="uppercase"
                            fontSize="12px"
                            fontWeight={700}
                          >
                            {item?.display}
                          </Text>
                        </Radio>
                      ))}
                    </HStack>
                    {formInscriptionEleve?.isSubmitted &&
                      typeResponsable1Checked === undefined && (
                        <Text color="red" fontWeight="500" fontSize="13px">
                          Veuillez sélectionner au moins une réponse.
                        </Text>
                      )}
                  </RadioGroup>
                </Stack>

                <HStack align="flex-start">
                  <FieldInput
                    w="100%"
                    name="responsables.0.nom"
                    label="Nom"
                    placeholder="John"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    w="100%"
                    name="responsables.0.prenom"
                    label="Prénom"
                    placeholder="Doe"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                </HStack>
                <FieldInput
                  w="100%"
                  name="responsables.0.adresse"
                  label="N° et nom de rue"
                  placeholder="151 rue de Paris"
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
                <HStack>
                  <FieldInput
                    w="100%"
                    name="responsables.0.codePostal"
                    type="tel"
                    label="Code Postal"
                    placeholder="76600"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    w="100%"
                    name="responsables.0.ville"
                    placeholder="Le Havre"
                    label="Ville"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                </HStack>
                <FieldInput
                  w="100%"
                  name="responsables.0.profession"
                  label="Profession"
                  placeholder="Président"
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
                <FieldInput
                  name="responsables.0.email"
                  label="Email"
                  placeholder="john@doe.com"
                  type="email"
                  validations={[
                    {
                      rule: isEmail(),
                      message: "Vérifiez le format de l'email",
                    },
                  ]}
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
                <FieldInput
                  name="responsables.0.telephone"
                  type="tel"
                  label="Numéro de téléphone"
                  placeholder="0656565656"
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
              </Stack>

              {/* REPSONSABLE 2*/}
              <Stack bgColor="rgba(0,0,0,.01)" p={2} borderRadius={12}>
                <Text textTransform="uppercase" fontWeight={700} fontSize={12}>
                  Responsable n°2
                </Text>

                {/* TYPE REPONSABLE */}
                <Stack spacing={1}>
                  <Text fontSize={14} fontWeight={600} color="#334155">
                    Choisir{" "}
                    <Badge
                      colorScheme="brand"
                      ml={1}
                      px={2}
                      py="2px"
                      borderRadius="6px"
                      textTransform="lowercase"
                    >
                      Requis
                    </Badge>
                  </Text>
                  <RadioGroup name="responsables.1.genre">
                    <HStack spacing={2}>
                      {typeResponsable?.map((item) => (
                        <Radio
                          key={item?.value}
                          value={item?.value}
                          onChange={(i) =>
                            setTypeResponsable2Checked(i?.target?.value)
                          }
                        >
                          <Text
                            textTransform="uppercase"
                            fontSize="12px"
                            fontWeight={700}
                          >
                            {item?.display}
                          </Text>
                        </Radio>
                      ))}
                    </HStack>
                    {formInscriptionEleve?.isSubmitted &&
                      typeResponsable2Checked === undefined && (
                        <Text color="red" fontWeight="500" fontSize="13px">
                          Veuillez sélectionner au moins une réponse.
                        </Text>
                      )}
                  </RadioGroup>
                </Stack>

                <HStack align="flex-start">
                  <FieldInput
                    w="100%"
                    name="responsables.1.nom"
                    label="Nom"
                    placeholder="John"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    w="100%"
                    name="responsables.1.prenom"
                    label="Prénom"
                    placeholder="Doe"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                </HStack>
                <Checkbox
                  onChange={(i) => {
                    setAdresseIdentique(i?.target?.checked);
                  }}
                  defaultChecked
                >
                  <Text>Adresse identique au responsable ci-dessus</Text>
                </Checkbox>
                {!adresseIdentique ? (
                  <>
                    <FieldInput
                      w="100%"
                      name="responsables.1.adresse"
                      label="N° et nom de rue"
                      placeholder="151 rue de Paris"
                      size="md"
                      borderRadius="8px"
                      required="Requis"
                      isSubmitted={formInscriptionEleve?.isSubmitted}
                    />
                    <HStack>
                      <FieldInput
                        w="100%"
                        name="responsables.1.codePostal"
                        type="tel"
                        label="Code Postal"
                        placeholder="76600"
                        size="md"
                        borderRadius="8px"
                        required="Requis"
                        isSubmitted={formInscriptionEleve?.isSubmitted}
                      />
                      <FieldInput
                        w="100%"
                        name="responsables.1.ville"
                        label="Ville"
                        placeholder="Le Havre"
                        size="md"
                        borderRadius="8px"
                        required="Requis"
                        isSubmitted={formInscriptionEleve?.isSubmitted}
                      />
                    </HStack>
                  </>
                ) : null}

                <FieldInput
                  w="100%"
                  name="responsables.1.profession"
                  label="Profession"
                  placeholder="Femme de président"
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
                <FieldInput
                  name="responsables.1.email"
                  label="Email"
                  placeholder="john@doe.com"
                  type="email"
                  validations={[
                    {
                      rule: isEmail(),
                      message: "Vérifiez le format de l'email",
                    },
                  ]}
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
                <FieldInput
                  name="responsables.1.telephone"
                  type="tel"
                  label="Numéro de téléphone"
                  placeholder="0643233423"
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
              </Stack>

              <Stack spacing={1}>
                <Text fontSize={14} fontWeight={600} color="#334155">
                  Genre
                  <Badge
                    colorScheme="brand"
                    ml={1}
                    px={2}
                    py="2px"
                    borderRadius="6px"
                    textTransform="lowercase"
                  >
                    Requis
                  </Badge>
                </Text>
                <RadioGroup name="eleve.genre">
                  <HStack spacing={2}>
                    {genre?.map((item) => (
                      <Radio
                        key={item?.value}
                        value={item?.value}
                        onChange={(i) => setGenreChecked(i?.target?.value)}
                      >
                        <Text
                          textTransform="uppercase"
                          fontSize="12px"
                          fontWeight={700}
                        >
                          {item?.display}
                        </Text>
                      </Radio>
                    ))}
                  </HStack>
                  {formInscriptionEleve?.isSubmitted &&
                    genreChecked === undefined && (
                      <Text color="red" fontWeight="500" fontSize="13px">
                        Veuillez sélectionner au moins une réponse.
                      </Text>
                    )}
                </RadioGroup>
              </Stack>

              <HStack align="flex-start">
                <FieldInput
                  w="100%"
                  name="eleve.nom"
                  label="Nom"
                  placeholder="JohnPetit"
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
                <FieldInput
                  w="100%"
                  name="eleve.prenom"
                  label="Prénom"
                  placeholder="DoePetit"
                  size="md"
                  borderRadius="8px"
                  required="Requis"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
              </HStack>
              <FieldDatePicker
                w="100%"
                name="eleve.dateNaissance"
                label="Date de naissance"
                placeholder="12/05/2012"
                required="Requis"
                size="md"
                isSubmitted={formInscriptionEleve?.isSubmitted}
              />
            </Stack>

            <Stack py={3}>
              <Checkbox
                name="accept"
                alignItems="flex-start"
                onChange={(i) => {
                  setAcceptImageChecked(i?.target?.checked);
                }}
              >
                J'autorise mon enfant a être photographié(e) lors des photos de
                classes et/ou des reportages photos et/ou vidéos effectués par
                l'AMH
              </Checkbox>
              <Checkbox
                name="accept"
                color={
                  formInscriptionEleve?.isSubmitted &&
                  acceptChecked === undefined
                    ? "red"
                    : null
                }
                alignItems="flex-start"
                onChange={(i) => {
                  setAcceptChecked(i?.target?.checked);
                }}
              >
                J'ai lu et accepté les modalités d'inscription{" "}
                <Badge
                  colorScheme="brand"
                  ml={1}
                  px={2}
                  py="2px"
                  borderRadius="6px"
                  textTransform="lowercase"
                >
                  Requis
                </Badge>
              </Checkbox>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
            <Button
              colorScheme="blue"
              onClick={formInscriptionEleve.submit}
              isLoading={isLoadingInscriptionEleve}
            >
              Ajouter
            </Button>
          </ModalFooter>
        </Formiz>
      </ModalContent>
    </Modal>
  );
}
