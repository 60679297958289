import { useToast } from "components/ui/use-toast";
import Axios from "config/axios";
import { useMutation } from "react-query";

/**
 * @author Issa Drici <issadricipro@gmail.com>
 * @returns
 */
export const useCreatePresencesCours = ({ handleCallbackSuccess }) => {
  // Hooks

  const { toast } = useToast();

  return useMutation(({ data }) => Axios.post("/presences-cours", data), {
    onSuccess: async () => {
      toast({
        description: "Appel enregistré !",
      })
      if (handleCallbackSuccess !== undefined) {
        handleCallbackSuccess();
      }
      return true;
    },
    onError: async (err) => {
      toast({
        variant: "destructive",
        title: "Oh là là ! Quelque chose s'est mal passé.",
        description: "Erreur de création",
      })
      return false;
    },
  });
};
