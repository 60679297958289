import {
  Avatar,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { useFindAllClasses } from "App/classe/services/useFindAllClasses";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useFindElevesByClasseId } from "../services/useFindElevesByClasseId";
import { useUpdateEleveClasse } from "../services/useUpdateEleveClasse";
import FormulaireEleveClasse from "./FormulaireEleveClasse";

export default function EleveList(props) {
  const { classeId, canCud } = props;

  const [currentEleveClasse, setCurrentEleveClasse] = useState();

  const queryClient = useQueryClient();
  const formEleveClasse = useForm({ subscribe: "form" });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [classeIdUpdated, setClasseIdUpdated] = useState();

  const {
    elevesClasse,
    isLoading: isLoadingElevesClasse,
    // isFetching: isFetchingELeves,
  } = useFindElevesByClasseId(classeId);

  const { classes, isLoading: isLoadingClasses } = useFindAllClasses();

  const handleCallbackSuccess = async () => {
    await queryClient.invalidateQueries("elevesClasse-classe", classeId);
    onClose();
  };

  const { mutate: updateEleveClasse } = useUpdateEleveClasse({
    eleveClasseId: currentEleveClasse?.id,
    handleCallbackSuccess,
  });

  const handleToggle = (value) => () => {
    setCurrentEleveClasse(value);
    onOpen();
  };

  const handleSubmit = (values) => {
    const data = {
      data: {
        ...values,
        eleve: currentEleveClasse?.eleve?.id ?? null,
        classe: classeIdUpdated ?? null,
      },
    };

    updateEleveClasse(data);
    return null;
  };

  return (
    <Stack spacing={5}>
      {isLoadingElevesClasse &&
        [0, 1, 2, 3, 4].map((item) => <Skeleton key={item} height="30px" />)}

      {!isLoadingElevesClasse && !isLoadingClasses && (
        <>
          <List width="100%" spacing={1}>
            {elevesClasse?.map((value) => {
              return (
                <ListItem
                  key={value?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgColor="white"
                  onClick={canCud && handleToggle(value)}
                  _hover={{
                    backgroundColor: "gray.100",
                  }}
                  _active={{ backgroundColor: "gray.200" }}
                  p={2}
                  borderRadius={10}
                  cursor="pointer"
                  shadow="base"
                >
                  <HStack>
                    <Avatar
                      size="sm"
                      name={`${value?.eleve?.nom} ${value?.eleve?.prenom}`}
                    />
                    <Text>{`${value?.eleve?.nom} ${value?.eleve?.prenom}`}</Text>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
          {canCud && (
            <Modal isOpen={isOpen} onClose={onClose} size="xs">
              <ModalOverlay />
              <ModalContent>
                <Formiz
                  autoform
                  connect={formEleveClasse}
                  onValidSubmit={handleSubmit}
                >
                  <ModalHeader>Changer de classe</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Stack
                      key={currentEleveClasse?.id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      bgColor="green.100"
                      _active={{ backgroundColor: "gray.200" }}
                      p={2}
                      borderRadius={10}
                      shadow="base"
                      mb={4}
                    >
                      <HStack>
                        <Avatar
                          size="sm"
                          name={`${currentEleveClasse?.eleve?.nom} ${currentEleveClasse?.eleve?.prenom}`}
                        />
                        <Text>{`${currentEleveClasse?.eleve?.nom} ${currentEleveClasse?.eleve?.prenom}`}</Text>
                      </HStack>
                    </Stack>
                    <FormulaireEleveClasse
                      classes={classes}
                      classeEleveId={classeId}
                      setClasseId={setClasseIdUpdated}
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                      Annuler
                    </Button>
                    <Button
                      colorScheme="green"
                      onClick={formEleveClasse.submit}
                    >
                      Enregistrer
                    </Button>
                  </ModalFooter>
                </Formiz>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </Stack>
  );
}

// TODO Find pour defaultValue
// TODO Appel existe pour ce jour et ce cours ?
