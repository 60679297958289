import CheckboxList from "app-components/list/CheckboxList";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useFindCoursById } from "App/cours/services/useFindCoursById";
import { Redirect, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { LayoutDefault } from "App/layout/LayoutDefault";
import BadgeCours from "App/cours/components/BadgeCours";

const PageAppel = () => {
  const { coursId } = useParams();

  const {
    cours,
    isLoading: isLoadingCours,
    // isFetching: isFetchingCours,
  } = useFindCoursById(coursId);

  if (!isLoadingCours) {
    if (!cours) {
      return <Redirect to="/404" />;
    }
  }

  const dateNow = dayjs();

  const weekDays = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  return (
    <LayoutDefault>
      <Stack paddingX={5} paddingY={3} spacing={5}>
        {isLoadingCours && <Skeleton height="50px" />}

        {!isLoadingCours && (
          <>
            <BadgeCours cours={cours} />
            <CheckboxList coursId={coursId} />
            {/* <Button variant="outlined" color="error">
        Supprimer
      </Button>*/}
            <Modal
              isCentered
              isOpen={cours?.jour !== weekDays[dateNow.day()]}
              size="xs"
            >
              <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
              />
              <ModalContent>
                <ModalHeader>
                  <Text textAlign="center">APPEL INDISPONIBLE</Text>
                </ModalHeader>
                <ModalBody>
                  <Stack spacing={3} mb={3}>
                    <BadgeCours cours={cours} />
                    <Box
                      border=" 3px solid"
                      borderColor="red.600"
                      px={3}
                      py={1}
                      borderRadius={10}
                    >
                      <Text textAlign="center" color="red.600" fontWeight={700}>
                        Scannez le QR Code associé au cours du jour
                      </Text>
                    </Box>
                  </Stack>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
      </Stack>
    </LayoutDefault>
  );
};

export default PageAppel;
