import {
  Box,
  HStack,
  Icon,
  Image,
  InputLeftElement,
  Progress,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { ButtonProfil } from "App/profil/components/ButtonProfil";
import { FieldInput } from "components/fields/FieldInput";
import NavbarDefault from "components/navbar/NavbarDefault";
import NavbarMobile from "components/navbar/NavbarMobile";
import React from "react";
import { FaSistrix } from "react-icons/fa";
import { Link } from "react-router-dom";

export const LayoutDefault = ({ isLoading, dataAvailable, children }) => {
  const isSmallDevice = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const formSearch = useForm({ subscribe: "form" });

  const handleSubmit = (values) => {
    return null;
  };

  return (
    <HStack
      minH="100vh"
      height="100%"
      maxWidth="100vw"
      width="100%"
      backgroundColor="#FEFEFE"
      align="flex-start"
    >
      {!isSmallDevice && <NavbarDefault />}

      <Stack
        minHeight="100vh"
        maxWidth="100vw"
        height="100%"
        flex={1}
        style={{ marginInlineStart: 0 }}
        pl={{ base: "0px", md: "16rem" }}
        spacing={0}
        overflow="hidden"
      >
        <Box
          pr={3}
          py={2}
          px={isSmallDevice ? 3 : null}
          display="flex"
          justifyContent={isSmallDevice ? "space-between" : "flex-end"}
          alignItems="center"
          borderBottom="1px solid #e2e8f0"
        >
          {isSmallDevice ? <NavbarMobile /> : null}
          <Stack flex={1} justify="center" align="center" as={Link} to="/">
            <Image src="/logo.png" h={25} alt="logo" />
          </Stack>
          <Formiz autoform connect={formSearch} onValidSubmit={handleSubmit}>
            <FieldInput
              w="100%"
              name="email"
              placeholder="Rechercher partout... (pas encore dispo)"
              variant="unstyled"
              inputLeftElement={
                <InputLeftElement h="full" pointerEvents="none">
                  <Icon as={FaSistrix} />
                </InputLeftElement>
              }
            />
          </Formiz>
          <ButtonProfil />
        </Box>
        {isLoading ? (
          <Progress height=".1rem" isIndeterminate colorScheme="brand" />
        ) : (
          <Box w="100%" height=".1rem" />
        )}
        {dataAvailable || !isLoading ? (
          <Stack px={8} py={6} spacing={3} height="100%">
            {children}
          </Stack>
        ) : null}
      </Stack>
    </HStack>
  );
};
