import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return absences
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindAllAbsences = (params) => {
  const { data, isLoading, isFetching } = useQuery(
    ["absences", params?.toString()],
    () => {
      return Axios.get(`/presences-cours/absents`, { params });
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const absences = data?.absences;

  return {
    absences,
    isLoading,
    isFetching,
  };
};
