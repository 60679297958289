import Axios from "config/axios";
import { useMutation } from "react-query";
import { useToast } from "components/ui/use-toast";

export const useUpdateResponsableLegal = ({ responsableLegauxId, handleCallbackSuccess }) => {
  const { toast } = useToast();

  return useMutation(( data ) => Axios.put(`/responsablesLegaux/${responsableLegauxId}`, data), {
    onSuccess: (responsableLegaux) => {
      if (handleCallbackSuccess !== undefined) {
        handleCallbackSuccess(responsableLegaux);
      }
      return true;
    },
    onError: (error, variables, context) => {
      toast({
        variant: "destructive",
        title: "Oh là là ! Quelque chose s'est mal passé.",
        description: error.response.data.detail,
      })
      return false;
    },
  });
};
