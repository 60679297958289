import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Wrap,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import FocusLock from "react-focus-lock";

const FilterCheckboxButton = ({
  leftIcon,
  id,
  label,
  defaultValue,
  options,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [optionsSelected, setOptionsSelected] = useState([]);

  const handleSetParam = () => {
      params.delete(id);
      optionsSelected?.forEach((item) => {
        params.append(id, item);
      });
      push({ search: params.toString() });
      onClose();

  };

  const handleDeleteParam = (e) => {
    e.preventDefault();

    params.delete(id);
    push({ search: params.toString() });
    setOptionsSelected([]);
  };

  const handleSelectOptions = (v) => {
    if (optionsSelected?.includes(v)) {
      setOptionsSelected(optionsSelected?.filter((id) => id !== v));
    } else {
      setOptionsSelected([...optionsSelected, v]);
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      shadow="lg"
    >
      <PopoverTrigger>
        <Button
          size="xs"
          minW="fit-content"
          variant={params.has(id) ? "solid" : "outline"}
          colorScheme="brand"
          leftIcon={leftIcon}
          rightIcon={
            params.has(id) ? (
              <Icon
                as={MdClose}
                color="white"
                _hover={{ color: "black" }}
                cursor="pointer"
                onClick={handleDeleteParam}
              />
            ) : null
          }
        >
          {params.has(id) ? params.get(id) : label}
        </Button>
      </PopoverTrigger>
      <PopoverContent p={3} shadow="lg">
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <FormControl>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <Wrap width="100%" align="center">
              {options?.map((value, key) => {
                return (
                  <WrapItem key={key}>
                    <Button
                      size="xs"
                      colorScheme="brand"
                      id={key}
                      variant={
                        optionsSelected?.includes(value) ? "solid" : "ghost"
                      }
                      onClick={() => handleSelectOptions(value)}
                    >
                      {value}
                    </Button>
                  </WrapItem>
                );
              })}
            </Wrap>
          </FormControl>

          <ButtonGroup display="flex" mt={3} justifyContent="flex-end">
            <Button
              size="sm"
              variant="ghost"
              onClick={onClose}
              colorScheme="blackAlpha"
            >
              Annuler
            </Button>
            <Button
              size="sm"
              variant="ghost"
              colorScheme="brand"
              onClick={handleSetParam}
            >
              Valider
            </Button>
          </ButtonGroup>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default FilterCheckboxButton;
