import {
  Accordion,
  Alert,
  AlertIcon,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import AccordionItem from "components/accordion/AccordionItem";
import React from "react";
import { AiFillPieChart } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import AbsenceItem from "../components/AbsenceItem";
import { useFindAllAbsences } from "../services/useFindAllAbsences";
import { PageHeader } from "App/layout/PageHeader";
import FilterMonthButton from "components/filter/filter-month/FilterMonthButton";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const PageAbsences = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { absences, isLoading: isLoadingAbsences } = useFindAllAbsences(params);
  const { push } = useHistory();

  return (
    <LayoutDefault>
      <Stack p={5} pt={0}>
        <PageHeader
          title="Absences"
          filters={
            <>
              <FilterMonthButton id="filter[date]" label="Période" />
            </>
          }
        >
          <HStack flex={{ base: 1, xl: 0 }} spacing={1} justify="flex-end">
            <Tooltip label="Statistiques">
              <IconButton
                onClick={() => push("/absences/stats")}
                aria-label="go-stats"
                icon={<AiFillPieChart />}
              />
            </Tooltip>
          </HStack>
        </PageHeader>

        <Stack spacing={5}>
          {isLoadingAbsences &&
            [0, 1, 2, 3, 4].map((item) => (
              <Skeleton key={item} height="30px" />
            ))}

          {!isLoadingAbsences ? (
            Object.entries(absences).length > 0 ? (
              <>
                <Stack spacing={5}>
                  {Object.entries(absences)?.map(([keyMonth, month]) => (
                    <Stack key={keyMonth}>
                      {/* <Heading size="sm">{keyMonth}</Heading>
                      <Divider mt={1} mb={3} /> */}

                        {Object.entries(month)?.map(([k, v]) => (
                          <Stack key={k}>
                            <Heading size="sm">{k}</Heading>

                            <Tabs isFitted variant="enclosed-colored">
                              <TabList>
                                {Object.entries(v)?.map(
                                  ([keyEtablissement, etablissement]) => (
                                    <Tab k={keyEtablissement}>
                                      {keyEtablissement}
                                    </Tab>
                                  )
                                )}
                              </TabList>
                              <TabPanels>
                                {Object.entries(v)?.map(
                                  ([keyEtablissement, etablissement]) => (
                                    <TabPanel
                                      key={keyEtablissement}
                                      border="1px solid rgb(226, 232, 240)"
                                    >
                                      <Accordion allowToggle>
                                        {Object.entries(etablissement)?.map(
                                          ([keyAbsences, absences]) => (
                                            <AccordionItem
                                              key={keyAbsences}
                                              label={keyAbsences}
                                              bgColor="#edf8ff"
                                              countChildren={absences?.length}
                                            >
                                              {absences?.map((value) => {
                                                return (
                                                  <AbsenceItem
                                                    absence={value}
                                                    key={value?.id}
                                                  />
                                                );
                                              })}
                                            </AccordionItem>
                                          )
                                        )}
                                      </Accordion>
                                    </TabPanel>
                                  )
                                )}
                              </TabPanels>
                            </Tabs>
                          </Stack>
                        ))}
                    </Stack>
                  ))}
                </Stack>
              </>
            ) : (
              <Alert status="warning">
                <AlertIcon />
                Aucun résultat. Merci de sélectionner une autre période.
              </Alert>
            )
          ) : null}
        </Stack>
      </Stack>
    </LayoutDefault>
  );
};

export default PageAbsences;
