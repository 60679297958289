import {
  HStack,
  Stack,
  StackDivider,
  useBreakpointValue,
} from "@chakra-ui/react";
import LabelValeur from "components/LabelValeur";
import Card from "components/card/Card";
import LinkContact from "components/link/LinkContact";
import React from "react";

const CardResponsableLegal = (props) => {
  const isSmallDevice = useBreakpointValue(
    { base: true, lg: false },
    { ssr: false }
  );

  const { responsableLegal } = props;

  const getImageGenre = (genre) => {
    if (genre === "pere") {
      return "/media/father-icon.png";
    } else if (genre === "mere") {
      return "/media/mother-icon.png";
    } else {
      return "/media/family-icon.png";
    }
  };

  return (
    <Card classContent="p-6">
      {isSmallDevice ? (
        <Stack divider={<StackDivider />} spacing={5}>
          <Stack flex={1} key={responsableLegal?.responsableLegaux?.id}>
            <HStack>
              <LabelValeur label="Profession" flex={1}>
                {responsableLegal?.responsableLegaux?.profession}
              </LabelValeur>
            </HStack>
            <HStack>
              <LabelValeur label="Email" flex={1}>
                <LinkContact
                  contact={responsableLegal?.responsableLegaux?.email}
                  type="email"
                />
              </LabelValeur>
              <LabelValeur label="Téléphone" flex={1}>
                <LinkContact
                  contact={responsableLegal?.responsableLegaux?.telephone}
                  type="phone"
                />
              </LabelValeur>
            </HStack>
            <LabelValeur label="Adresse Postale">
              {responsableLegal?.responsableLegaux?.adresse},{" "}
              {responsableLegal?.responsableLegaux?.codePostal}{" "}
              {responsableLegal?.responsableLegaux?.ville}
            </LabelValeur>
          </Stack>
        </Stack>
      ) : (
        <div className="flex gap-x-5 align-top">
          <img
            className="h-20 w-20"
            src={getImageGenre(responsableLegal?.responsableLegaux?.genre)}
            alt="genre"
          />
          <div className="gap-2">
            <p className="text-base font-medium">
              {responsableLegal?.responsableLegaux?.prenom}{" "}
              {responsableLegal?.responsableLegaux?.nom}
            </p>
            <p className="text-sm text-primary">
              {responsableLegal?.responsableLegaux?.profession}
            </p>
            <p className="text-sm text-slate-700">
              {responsableLegal?.responsableLegaux?.adresse},{" "}
              <span className="text-xs">
                {responsableLegal?.responsableLegaux?.codePostal} -{" "}
                {responsableLegal?.responsableLegaux?.ville}
              </span>
            </p>
            <div>
              <a
                className="text-sm text-slate-700 underline"
                href={`mailto:${responsableLegal?.responsableLegaux?.email}`}
              >
                {responsableLegal?.responsableLegaux?.email}
              </a>
            </div>
            <div>
              <a
                className="text-sm text-slate-700 underline"
                href={`tel:${responsableLegal?.responsableLegaux?.telephone}`}
              >
                {responsableLegal?.responsableLegaux?.telephone}
              </a>
            </div>
          </div>
        </div>
        // <HStack divider={<StackDivider />} spacing={5}>
        //   <Stack flex={1} key={responsableLegal?.responsableLegaux?.id}>
        //     <HStack>
        //       <LabelValeur label="Profession" flex={1}>
        //         {responsableLegal?.responsableLegaux?.profession}
        //       </LabelValeur>
        //     </HStack>
        //     <HStack>
        //       <LabelValeur label="Email" flex={1}>
        //         <LinkContact
        //           contact={responsableLegal?.responsableLegaux?.email}
        //           type="email"
        //         />
        //       </LabelValeur>
        //       <LabelValeur label="Téléphone" flex={1}>
        //         <LinkContact
        //           contact={responsableLegal?.responsableLegaux?.telephone}
        //           type="phone"
        //         />
        //       </LabelValeur>
        //     </HStack>
        //     <LabelValeur label="Adresse Postale">
        //       {responsableLegal?.responsableLegaux?.adresse},{" "}
        //       {responsableLegal?.responsableLegaux?.codePostal}{" "}
        //       {responsableLegal?.responsableLegaux?.ville}
        //     </LabelValeur>
        //   </Stack>
        // </HStack>
      )}
    </Card>
  );
};

export default CardResponsableLegal;
