import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { DataTable } from "components/ui/data-table";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { DataTableRowActions } from "components/ui/data-table-row-actions";
import { DropdownMenuItem } from "components/ui/dropdown-menu";
import { MenubarShortcut } from "components/ui/menubar";
import { TrashIcon } from "@radix-ui/react-icons";
import { useDeleteEleveClasse } from "App/eleve/services/useDeleteEleveClasse";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { useState } from "react";

const DialogEleveClasses = (props) => {
  const { eleveClasses, open, setOpen, canEdit } = props;
  const queryClient = useQueryClient();
  const { eleveId } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [currentToDeleteId, setCurrentToDeleteId] = useState();

  const handleCallbackSuccess = async () => {
    await queryClient.invalidateQueries(["elevesClasse-eleve", eleveId]);
  };

  const { mutate: mutationDelete } = useDeleteEleveClasse({
    handleCallbackSuccess,
  });

  const handleDelete = (id) => {
    mutationDelete(id);
  };

  const columns = [
    {
      accessorKey: "classe.etablissement.nom",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Établ." />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "classe.nom",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Nom" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "anneeScolaire.label",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Année scolaire" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <DataTableRowActions row={row}>
              <DropdownMenuItem
                onClick={() => {
                  setCurrentToDeleteId(row?.original?.id);
                  setIsOpen(true);
                }}
              >
                Supprimer{" "}
                <MenubarShortcut>
                  <TrashIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </DropdownMenuItem>
            </DataTableRowActions>
          </div>
        );
      },
    },
  ];

  return (
    <Dialog
      open={open}
      onOpenChange={(isOp) => {
        if (isOp === true) return;
        setOpen(false);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Gérer les classes</DialogTitle>
          <DialogDescription>
            Apportez des modifications aux classes attribuées à l'élève ici.
            Cliquez sur la croix lorsque vous avez terminé.
          </DialogDescription>
        </DialogHeader>
        <div className="w-full">
          <AlertDialog
            open={isOpen}
            onOpenChange={(isOp) => {
              if (isOp === true) return;
              setIsOpen(false);
              setCurrentToDeleteId(null);
            }}
          >
            <DataTable
              columns={columns}
              data={eleveClasses}
              hideToolbar
              hidePagination
            />
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Êtes-vous absolument sûr ?</AlertDialogTitle>
                <AlertDialogDescription>
                  Cette action ne peut pas être annulée. Cela supprimera l'élève
                  de cette classe.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Annuler</AlertDialogCancel>
                <AlertDialogAction
                  className="bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90"
                  onClick={() => handleDelete(currentToDeleteId)}
                >
                  Continuer
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogEleveClasses;
