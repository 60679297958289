// import { useForm } from "@formiz/core";
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Separator } from "components/ui/separator";
// import { useUpdateResponsableLegal } from "../services/useUpdateResponsableLegal";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Button from "components/button/Button";
import { useCreateProfesseur } from "../services/useCreateProfesseur";

const formSchema = z.object({
  nom: z.string().min(2, {
    message: "Le nom doit comporter min 2 caractères.",
  }),
  prenom: z.string().min(2, {
    message: "Le prénom doit comporter min 2 caractères.",
  }),
  email: z.string().min(2, {
    message: "L'email doit comporter min 2 caractères.",
  }),
  telephone: z.string(),
  password: z.string(),
});

const DialogProfesseur = (props) => {
  const { open, setOpen } = props;
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const form = useForm({
    resolver: zodResolver(formSchema),
    // defaultValues: {
    // },
  });

  const handleCallbackSuccess = async ({ inscription }) => {
    await queryClient.invalidateQueries(
      "eleveResponsableLegaux",
      params?.toString()
    );
    setOpen(false);
  };

  const { mutate: createProfesseur, isLoading: isLoadingCreateProfesseur } =
    useCreateProfesseur({
      handleCallbackSuccess,
    });

  function onSubmit(values) {
    const data = {
      ...values,
    };
    console.log(data);
    createProfesseur(data);
    return null;
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        if (isOpen === true) return;
        setOpen(false);
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <DialogHeader>
              <DialogTitle>Créer un professeur</DialogTitle>
              <DialogDescription>
                Vous pouvez créer un professeur depuis ce formulaire. Pensez à
                bien transmettre le mot de passe associé. Cliquez sur
                Enregistrer lorsque vous avez terminé.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="nom"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Nom</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="Nom du professeur"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="prenom"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Prénom</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="Prénom du professeur"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Email</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="Email du professeur"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="telephone"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Téléphone</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="Téléphone du professeur"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
              <Separator />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Mot de passe</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="Mot de passe du professeur"
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2" />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button isLoading={isLoadingCreateProfesseur} type="submit">
                Enregistrer
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogProfesseur;
