import { Stack, Text } from "@chakra-ui/react";
import React from "react";

const Page404 = () => {
  return (
    <Stack h="100vh" justify="center" align="center">
      <Text textAlign="center" fontSize="40px" fontWeight={700}>
        Erreur 404
      </Text>
      <Text textAlign="center" fontSize="30px"  fontWeight={700}>
        Page Introuvable
      </Text>
    </Stack>
  );
};

export default Page404;
