import { extendTheme } from "@chakra-ui/react";

const colors = {
  // Go to https://smart-swatch.netlify.com/ to easily generate a new color palette.
  // brand: {
  //   vertElectrique: "#18d94b",
  //   defaultTheme: {
  //     500: "#f3f5fc",
  //     600: "#63def4",
  //     700: "#0aa4e0",
  //   },
  //   grisClair: "#f5f5f5",
  //   grisBackground: "#f8f8f8",
  //   grisTexte: "#6e6b7b",
  // },
  brand: {
    50: "#e1ffec",
    100: "#b8f7cf",
    200: "#8ef0b2",
    300: "#62ea94",
    400: "#38e476",
    500: "#20ca5d",
    600: "#159d47",
    700: "#0b7032",
    800: "#02431d",
    900: "#001804",
  },
};

const fonts = {
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
};

const shadows = {
  vertElectrique: "0 0 10px 1px rgba(18, 227, 74, 0.7)",
  navItem: "0 0 10px 1px rgba(48,213,241,.7)",
  outline: "none",
};

const Text = {
  baseStyle: {
    color: "#6e6b7b",
    fontFamily: `'Inter', sans-serif`,
  },
};

const Heading = {
  baseStyle: {
    color: "##334155",
    fontFamily: `'Inter', sans-serif`,
  },
};

const Input = {
  baseStyle: {
    fontFamily: `'Inter', sans-serif`,
  },
};

const Button = {
  baseStyle: {
    fontFamily: `'Inter', sans-serif`,
  },
  variants: {
    default: {
      background: "#000",
    },
  },
};

export const customTheme = extendTheme({
  components: {
    Text,
    Heading,
    Input,
    Button,
  },
  colors,
  shadows,
  fonts,
});
