import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return anneesScolaire
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindAllAnneesScolaire = () => {
  const { data, isLoading, isFetching } = useQuery(
    "anneesScolaire",
    () => {
      return Axios.get(`/annees-scolaire`);
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const anneesScolaire = data?.anneesScolaire;
  
  return {
    anneesScolaire,
    isLoading,
    isFetching,
  };
};
