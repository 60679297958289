import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaBars } from "react-icons/fa";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import Button from "components/button/Button";import { DotsVerticalIcon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import {
  Card as CardShadn,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";

const Card = (props) => {
  const {
    children,
    onClick,
    title,
    colorTitle,
    menuItems,
    footer,
    description,
    classCard,
    classContent,
  } = props;

  return (
    <CardShadn className={`overflow-hidden ${classCard}`}>
      {title ? (
        <CardHeader className="pl-6 pr-2 pt-2 pb-2">
          <div className="flex flex-row justify-between">
            <CardTitle className="text-base py-2">
              {typeof title === "string"
                ? title
                    ?.split(" ")
                    ?.map((word) => {
                      return word[0].toUpperCase() + word.substring(1);
                    })
                    .join(" ")
                : title}
            </CardTitle>
            {menuItems ? (
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button variant="outline" size="icon">
                    <DotsVerticalIcon className="h-3 w-3" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>{menuItems}</DropdownMenuContent>
              </DropdownMenu>
            ) : null}
          </div>
          {description ? (
            <CardDescription>{description}</CardDescription>
          ) : null}
        </CardHeader>
      ) : null}
      <CardContent className={classContent}>{children}</CardContent>
      {footer ? <CardFooter>{footer}</CardFooter> : null}
    </CardShadn>

    // <Stack
    //   position="relative"
    //   bg="white"
    //   borderRadius="md"
    //   border="2px solid"
    //   borderColor="gray.50"
    //   shadow="lg"
    //   w="100%"
    //   height="100%"
    //   overflow="hidden"
    //   // justifyContent={title && "space-between"}
    //   cursor={onClick ? "pointer" : null}
    //   onClick={onClick}
    //   transition="all .2s ease"
    //   _hover={
    //     onClick && {
    //       backgroundColor: "gray.50",
    //       shadow: "xl",
    //       transform: "scale(1.02)",
    //       zIndex: 100,
    //     }
    //   }
    //   {...rest}
    // >
    //   {!title && children}
    //   {title && (
    //     <>
    //       <HStack justify="space-between" pl={3} pt={2} pr={1} pb={1}>
    //         <Text fontWeight={700} color="gray.700">
    //           {title
    //             ?.split(" ")
    //             ?.map((word) => {
    //               return word[0].toUpperCase() + word.substring(1);
    //             })
    //             .join(" ")}
    //         </Text>
    //         {menuItems ? (
    //           <DropdownMenu>
    //             <DropdownMenuTrigger>
    //               <Button variant="outline" size="icon">
    //                 <HamburgerMenuIcon className="h-4 w-4" />
    //               </Button>
    //             </DropdownMenuTrigger>
    //             <DropdownMenuContent>{menuItems}</DropdownMenuContent>
    //           </DropdownMenu>
    //         ) : null}
    //       </HStack>
    //       <Stack px={5} pb={3} {...rest}>
    //         {children}
    //       </Stack>
    //     </>
    //   )}
    // </Stack>
  );
};

export default Card;
