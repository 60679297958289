import {
//   Avatar,
//   Badge,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { FiMenu, FiPhoneCall, FiSend } from "react-icons/fi";

const AbsenceItem = (props) => {
  const { absence } = props;
//   console.log(absence, 'tre')
  const [isLargerThan375] = useMediaQuery("(min-width: 375px)");

  return (
    <HStack
      fontSize=".85rem"
      justify="space-between"
      p={2}
      borderRadius={10}
      shadow="base"
    >
      {/* <Avatar
        size="xs"
        name={`${absence?.cours?.classe?.etablissement?.nom[0]} ${absence?.cours?.classe?.etablissement?.nom[1]}`}
      /> */}
      <Stack flex={1} spacing={0}>
        <Text fontWeight={700}>{absence?.eleve?.nom}</Text>
        <Text fontWeight={500}>{absence?.eleve?.prenom}</Text>
      </Stack>
      <Stack flex={1} spacing={0}>
        <Text fontWeight={700}>{absence?.cours?.jour}</Text>
        <Text fontSize=".6rem" fontWeight={700}>
          {absence?.cours?.heure}
        </Text>
      </Stack>
      {/* <Stack flex={1.5} spacing={0} align="center">
        <Badge width="fit-content" fontSize=".8rem">
          {absence?.cours?.classe?.nom}
        </Badge>
        <Text fontWeight={700}>{dayjs(absence?.date).format("DD/MM")}</Text>
      </Stack> */}
      {isLargerThan375 ? (
        <>
          <IconButton
            aria-label="Call"
            icon={<FiPhoneCall />}
            href={`tel:${absence?.eleve?.telephone}`}
            as="a"
          />
          <IconButton
            aria-label="Send Message"
            icon={<FiSend />}
            href={`sms:${
              absence?.eleve?.telephone
            }&body=As salam Aleykoum,%0a%0aNous attirons votre attention sur l'absence de votre enfant ${
              absence?.eleve?.nom
            } ${absence?.eleve?.prenom} en date du ${dayjs(
              absence?.date
            ).format(
              "DD/MM/YYYY"
            )}%0a%0aPersuadés que cette absence est justifiée, nous vous remercions de prendre contact avec le pôle enseignement au 06 95 50 78 76 dans les plus bref délais afin de confirmer le motif de son absence.%0a%0aNous vous rappelons qu’au bout de 5 absences non justifiées, l’enfant est susceptible d'être désinscrit.%0a%0aBarakallahoufikoum pour votre compréhension%0a%0aAMH Département enseignement`}
            as="a"
          />
        </>
      ) : (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FiMenu />}
            variant="outline"
            size="sm"
          />
          <MenuList>
            <MenuItem
              icon={<FiPhoneCall />}
              href={`tel:${absence?.eleve?.telephone}`}
              as="a"
            >
              Appeler
            </MenuItem>
            <MenuItem
              icon={<FiSend />}
              href={`sms:${
                absence?.eleve?.telephone
              }&body=As salam Aleykoum,%0a%0aNous attirons votre attention sur l'absence de votre enfant ${
                absence?.eleve?.nom
              } ${absence?.eleve?.prenom} en date du ${dayjs(
                absence?.date
              ).format(
                "DD/MM/YYYY"
              )}.%0a%0aPersuadés que cette absence est justifiée, nous vous remercions de prendre contact avec le pôle enseignement au 06 95 50 78 76 dans les plus bref délais afin de confirmer le motif de son absence.%0a%0aNous vous rappelons qu’au bout de 5 absences non justifiées, l’enfant est susceptible d'être désinscrit.%0a%0aBarakallahoufikoum pour votre compréhension%0a%0aAMH Département enseignement`}
              as="a"
            >
              Message
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};

export default AbsenceItem;
