import { Badge } from "@chakra-ui/react";
// import { useForm } from "@formiz/core";
import React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useDeleteEleve } from "../services/useDeleteEleve";
import { useUpdateEleve } from "../services/useUpdateEleve";
import Button from "components/button/Button";import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { DatePicker } from "components/ui/date-picker";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";

const formSchema = z.object({
  nom: z.string().min(2, {
    message: "Le nom doit comporter min 2 caractères.",
  }),
  prenom: z.string().min(2, {
    message: "Le prénom doit comporter min 2 caractères.",
  }),
});

const DialogEleve = (props) => {
  const { eleve, open, setOpen, canEdit } = props;

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nom: eleve?.nom,
      prenom: eleve?.prenom,
      dateNaissance: eleve?.dateNaissance,
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);
  }

  // const formEleve = useForm({ subscribe: "form" });
  // const [isEditing, setIsEditing] = useState(false);

  // const { push } = useHistory();

  // const queryClient = useQueryClient();

  // const handleCallbackSuccess = async () => {
  //   setIsEditing(false);
  //   await queryClient.invalidateQueries("eleve", eleve?.id);
  // };

  // const { mutate: updateEleve } = useUpdateEleve({
  //   eleveId: eleve?.id,
  //   handleCallbackSuccess,
  // });

  // const handleCallbackSuccessDelete = async () => {
  //   await queryClient.invalidateQueries(["eleves"]);
  //   push("/eleves");
  // };

  // const { mutate: mutationDelete } = useDeleteEleve({
  //   handleCallbackSuccess: handleCallbackSuccessDelete,
  // });

  // const handleDelete = () => {
  //   mutationDelete(eleve?.id);
  // };

  // // SUBMIT CALL
  // const handleSubmitEleve = (values) => {
  //   const data = {
  //     data: {
  //       ...values,
  //     },
  //   };
  //   // Update query
  //   // if (updateForm) {
  //   updateEleve(data);
  //   return null;
  //   // }

  //   // Create query
  //   // createClient(data);
  //   // return null;
  // };

  // const getStatut = () => {
  //   if (inscription?.dtInscription && !inscription?.dtValidation) {
  //     return <Badge colorScheme="blue">En attente</Badge>;
  //   }
  //   if (inscription?.dtValidation) {
  //     return <Badge colorScheme="green">Validé</Badge>;
  //   }
  // };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        if (isOpen === true) return;
        setOpen(false);
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <DialogHeader>
              <DialogTitle>Modifier un élève</DialogTitle>
              <DialogDescription>
                Apportez des modifications à l'élève ici. Cliquez sur
                Enregistrer lorsque vous avez terminé.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="nom"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Nom</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2"/>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="prenom"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Prénom</FormLabel>
                    <FormControl>
                      <Input
                        className="col-span-3"
                        placeholder="shadcn"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2"/>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dateNaissance"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-x-4">
                    <FormLabel className="text-right">Date de naissance</FormLabel>
                    <FormControl>
                      <DatePicker className="col-span-3 w-full" {...field} />
                    </FormControl>
                    <FormMessage className="col-span-3 col-start-2"/>
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit">Enregistrer</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogEleve;
