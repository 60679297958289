import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format, setYear } from "date-fns";

import { cn } from "lib/utils";
import Button from "components/button/Button";import { Calendar } from "components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { fr } from "date-fns/locale";

export function DatePicker({ className }) {
  const [date, setDate] = React.useState();
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground",
            className
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            format(date, "dd-MM-yyyy")
          ) : (
            <span>Sélectionnez une date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="flex w-auto flex-col space-y-2 p-2"
      >
        {/* <div className="flex gap-x-2">
          <Select
            className="flex-1"
            onValueChange={(value) =>
              setDate(addDays(new Date(), parseInt(value)))
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Mois" />
            </SelectTrigger>
            <SelectContent position="popper">
              <SelectItem value="0">Today</SelectItem>
              <SelectItem value="1">Tomorrow</SelectItem>
              <SelectItem value="3">In 3 days</SelectItem>
              <SelectItem value="7">In a week</SelectItem>
            </SelectContent>
          </Select>
          <Select
            className="flex-1"
            onValueChange={(value) =>
              setDate(setYear(new Date(), parseInt(value)))
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Année" />
            </SelectTrigger>
            <SelectContent className="max-h-64" position="popper">
              {Array.from(
                { length: 101 },
                (_, index) => new Date().getFullYear() - index
              )?.map((y) => (
                <SelectItem value={y}>{y}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div> */}
        <div className="rounded-md border">
          <Calendar
            mode="single"
            selected={date}
            onSelect={setDate}
            // month={date}
            locale={fr}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
