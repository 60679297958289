import {
  Divider,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import Card from "components/card/Card";
import React from "react";
import { BsListNested } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useFindAbsencesDataPieChart } from "../services/useFindAbsencesDataPieChart";
import dayjs from "dayjs";

const PageAbsencesStatistiques = () => {
  const { push } = useHistory();

  const { dataPieChart, isLoading: isLoadingDataPieChart } =
    useFindAbsencesDataPieChart();

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {value}
      </text>
    );
  };

  return (
    <LayoutDefault>
      <Card>
        <HStack justify="space-between">
          <Heading size="md">Absences - Statistiques</Heading>
          <Tooltip label="Listes">
            <IconButton
              onClick={() =>
                push(
                  `/absences?filter[date][du]=${dayjs().format(
                    "YYYY-MM-01"
                  )}&filter[date][au]=${dayjs().format(
                    `YYYY-MM-${dayjs().daysInMonth()}`
                  )}`
                )
              }
              aria-label="go-listes"
              icon={<BsListNested />}
            />
          </Tooltip>
        </HStack>
      </Card>
      <Stack spacing={5}>
        {isLoadingDataPieChart && (
          <HStack>
            {[0, 1, 2, 3, 4]?.map((item) => {
              return <Skeleton key={item} width="120px" height="150px" />;
            })}
          </HStack>
        )}

        {!isLoadingDataPieChart && (
          <>
            <Stack spacing={12}>
              {Object.entries(dataPieChart)?.map(
                ([keyEtablissement, etablissement]) => (
                  <Stack key={keyEtablissement}>
                    <Heading size="sm">{keyEtablissement}</Heading>
                    <Divider mt={1} mb={3} />
                    <SimpleGrid
                      columns={{ base: 2, md: 4, lg: 5 }}
                      spacing={{ base: 2, md: 4, lg: 5 }}
                    >
                      {etablissement?.map((cours) => {
                        const dataPie = [
                          {
                            name: "absents",
                            value: parseInt(cours?.moyenne_absents),
                          },
                          {
                            name: "presents",
                            value: parseInt(cours?.moyenne_presents),
                          },
                        ];

                        return (
                          <Card justifyContent="space-between" minHeight={330}>
                            <HStack
                              w="100%"
                              bgColor="brand.vertElectrique"
                              px={2}
                              py={1}
                              borderRadius={7}
                              justify="center"
                            >
                              <Text
                                textAlign="center"
                                color="white"
                                fontWeight={700}
                              >
                                {cours?.classe_nom} | {cours?.jour}{" "}
                                {cours?.heure}
                              </Text>
                            </HStack>
                            <Stack
                              minHeight={200}
                              w="100"
                              align="center"
                              justify="center"
                            >
                              <ResponsiveContainer height="100%" width="100%">
                                <PieChart width={800} height={400}>
                                  <Pie
                                    data={dataPie}
                                    dataKey="value"
                                    nameKey="name"
                                    fill="#8884d8"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    innerRadius={40}
                                    outerRadius={80}
                                    paddingAngle={3}
                                  >
                                    {dataPie.map((entry, index) => {
                                      return (
                                        <Cell
                                          key={`cell-${index}`}
                                          fill={
                                            entry?.name === "absents"
                                              ? "#FF8042"
                                              : "#00C49F"
                                          }
                                        />
                                      );
                                    })}
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                            </Stack>
                            <Text textAlign="center" fontWeight={700}>
                              TOTAL : {cours?.total_absents}
                            </Text>
                          </Card>
                        );
                      })}
                    </SimpleGrid>
                  </Stack>
                )
              )}
            </Stack>
          </>
        )}
      </Stack>
    </LayoutDefault>
  );
};

export default PageAbsencesStatistiques;
