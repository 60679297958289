import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return Entreprises
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindPresencesCoursByCoursIdAndDate = (coursId, date) => {
  const { data, isLoading } = useQuery(["presences-cours", coursId], () => {

    if (coursId) {
      return Axios.get(`/presences-cours/${coursId}/cours?dateAppel=${date}`);
    }
  });

  const presencesCours = data?.presencesCours;

  return {
    presencesCours,
    isLoading,
  };
};
