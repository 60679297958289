import {
  Badge,
  Box,
  HStack,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import Card from "components/card/Card";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthentificationContext } from "context/auth-context";
import FilterTextButton from "components/filter/filter-text/FilterTextButton";
import dayjs from "dayjs";
import FilterCheckboxButton from "components/filter/filter-checkbox/FilterCheckboxButton";
import { useFindInscriptionByFilters } from "App/inscription/services/useFindInscriptionByFilters";
import { PageHeader } from "App/layout/PageHeader";
import { Formiz, useForm } from "@formiz/core";
import { ReactSelect } from "components/react-select/ReactSelect";
import { isObjectEmpty } from "functions/common-scope/ValidationType";
import { useFindAllClasses } from "App/classe/services/useFindAllClasses";
import { useFindInscriptionForAttributionByFilters } from "App/inscription/services/useFindInscriptionForAttributionByFilters";
import { useCreateEleveClasse } from "../services/useCreateEleveClasse";
import { useUpdateEleveClasse } from "../services/useUpdateEleveClasse";
import { useQueryClient } from "react-query";

const PageAttributionClasses = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const queryClient = useQueryClient();

  const formAttributionClasse = useForm({ subscribe: "form" });

  const { inscription, isLoading } =
    useFindInscriptionForAttributionByFilters(params);

  const { classes, isLoading: isLoadingClasses } = useFindAllClasses();

  const classeCE = classes?.CE?.map((item) => ({
    value: item?.id,
    label: `${item?.etablissement?.nom} - ${item?.nom}`,
  }));

  const classeCIH = classes?.CIH?.map((item) => ({
    value: item?.id,
    label: `${item?.etablissement?.nom} - ${item?.nom}`,
  }));

  if (!isLoadingClasses) {
    var optionsClasses = [...classeCE, ...classeCIH];
  }

  const { isAdmin, isEnseignement } = useAuthentificationContext();

  const handleCallbackSuccess = async () => {
    await queryClient.invalidateQueries(
      "inscription-for-attribution",
      params?.toString()
    );
  };

  const { mutate: createEleveClasse, isLoading: isLoadingCreateEleveClasse } =
    useCreateEleveClasse({
      handleCallbackSuccess,
    });

  const handleCallbackSuccessUpdate = async () => {
    await queryClient.invalidateQueries(
      "inscription-for-attribution",
      params?.toString()
    );
  };

  const { mutate: updateEleveClasse } = useUpdateEleveClasse({
    handleCallbackSuccessUpdate,
  });

  const attribuateClass = ({ eleveId, value, eleveClasseId }) => {
    const data = {
      data: {
        eleve: eleveId ?? null,
        classe: value ?? null,
        eleveClasse: eleveClasseId ?? null,
        anneeScolaire: 1,
      },
    };

    if (!eleveClasseId) {
      createEleveClasse(data);
    } else {
      updateEleveClasse(data);
    }

    return null;
  };

  return (
    <LayoutDefault isLoading={isLoading} dataAvailable={!!inscription}>
      <Stack>
        <PageHeader
          title="Attribution des classes"
          filters={
            <>
              <FilterTextButton id="filter[eleve][nom]" label="Nom" />
              <FilterTextButton id="filter[eleve][prenom]" label="Prénom" />
              <FilterCheckboxButton
                id="filter[statut][]"
                label="Statut"
                options={["En attente", "Validé"]}
              />
              <FilterCheckboxButton
                id="filter[etablissementAnterieur][]"
                label="Établissement 2022/2023"
                options={["CE", "CIH"]}
              />
              <FilterCheckboxButton
                id="filter[classeAnterieure][]"
                label="Classe 2022/2023"
                options={[
                  "Mini Club 1",
                  "Mini Club 2",
                  "CP A",
                  "CP B",
                  "CP C",
                  "N Préparatoire",
                  "N1 A",
                  "N1 B",
                  "N1 C",
                  "N1 D",
                  "N2 A",
                  "N2 B",
                  "N2 C",
                  "N3",
                  "N4",
                  "N5",
                  "N6",
                  "N Jeune Débutant",
                  "N Jeune 1",
                  "N Jeune 2",
                ]}
              />
            </>
          }
        />
        {/*   <HStack flex={{ base: 1, xl: 0 }} spacing={1} justify="flex-end">
            {(isAdmin || isEnseignement) && (
              <>
               <Tooltip label="Exporter">
                  <IconButton
                    onClick={() => handleExtract()}
                    aria-label="Extract"
                    icon={<AiOutlineCloudDownload />}
                    isLoading={isLoadingExtract}
                  />
                </Tooltip> 
              </>
            )}
          </HStack> 
        </PageHeader>*/}
        <Card p={0} maxWidth="100%">
          <TableContainer minHeight="70vh">
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>Nom</Th>
                  <Th>Prénom</Th>
                  <Th>Date de naissance</Th>
                  <Th>Code Postal</Th>
                  <Th>Année pré.</Th>
                  <Th>Attribution</Th>
                </Tr>
              </Thead>
              <Tbody>
                {inscription?.map((inscri) => (
                  <Tr>
                    <Td>{inscri?.inscription?.eleve?.nom}</Td>
                    <Td>{inscri?.inscription?.eleve?.prenom}</Td>
                    <Td>{`${
                      inscri?.inscription?.eleve?.dateNaissance
                    } (${dayjs().diff(
                      dayjs(
                        inscri?.inscription?.eleve?.dateNaissance,
                        "DD-MM-YYYY"
                      ),
                      "year"
                    )} ans)`}</Td>
                    <Td>{inscri?.responsableLegaux?.codePostal}</Td>
                    <Td>
                      <HStack>
                        <Badge
                          colorScheme={
                            inscri?.inscription?.etablissementAnterieur ===
                            "CIH"
                              ? "orange"
                              : "yellow"
                          }
                        >
                          {inscri?.inscription?.etablissementAnterieur}
                        </Badge>
                        <Badge>{inscri?.inscription?.classeAnterieure}</Badge>
                      </HStack>
                    </Td>
                    <Td>
                      <Formiz autoform connect={formAttributionClasse}>
                        <ReactSelect
                          callbackOnChange={(e) =>
                            attribuateClass({
                              eleveId: inscri?.inscription?.eleve?.id,
                              value: e?.value,
                              eleveClasseId: inscri?.eleveClasse?.id,
                            })
                          }
                          defaultValue={
                            inscri?.eleveClasse?.id
                              ? optionsClasses?.filter(
                                  (item) =>
                                    item?.value ===
                                    parseInt(inscri?.eleveClasse?.classe?.id)
                                )
                              : null
                          }
                          name="classe"
                          className="basic-single"
                          placeholder="Sélectionner..."
                          options={optionsClasses}
                          // isClearable
                        />
                      </Formiz>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>
      <Box
        size="xs"
        position="absolute"
        bottom={5}
        left={5}
        zIndex={999}
        background="red.500"
        paddingX={5}
        paddingY={2}
        borderRadius="md"
      >
        <Text color="white" fontWeight="bold">
          Restant :{" "}
          {inscription?.reduce((compteur, element) => {
            if (!element?.eleveClasse) {
              return compteur + 1;
            }
            return compteur;
          }, 0)}{" "}
          élèves sans classe
        </Text>
      </Box>
    </LayoutDefault>
  );
};

export default PageAttributionClasses;
