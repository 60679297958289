import {
  Box,
  Heading,
  HStack,
  IconButton,
  Stack,
  StackDivider,
  useBreakpointValue,
} from "@chakra-ui/react";
import Breadcrumb from "components/breadcrumb/Breadcrumb";
import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export const PageHeader = ({ title, filters, buttons, children }) => {
  const isSmallDevice = useBreakpointValue(
    { base: true, xl: false },
    { ssr: false }
  );
  const [scrollPosition, setScrollPosition] = useState(0);

  const div = document.getElementById("filters");

  const handleClickScrollRight = () => {
    if (scrollPosition >= 150) {
      const scrollPositionTemp = scrollPosition - 150;
      setScrollPosition(scrollPositionTemp);
    }
  };

  const handleClickScrollLeft = () => {
    if (scrollPosition < div?.offsetWidth - 400) {
      const scrollPositionTemp = scrollPosition + 150;
      setScrollPosition(scrollPositionTemp);
    }
  };

  useEffect(() => {
    if (div) {
      setScrollPosition(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (div) {
      div.scrollTo({ left: scrollPosition, behavior: "smooth" });
    }
  }, [div, scrollPosition]);

  if (filters) {
    return (
      <Stack spacing={3}>
        <HStack justify="space-between" w="100%">
          <Heading fontSize={isSmallDevice ? 20 : 24} fontWeight={500}>
            {title}
          </Heading>
          {buttons ? buttons : null}
          {/* {!isSmallDevice && <Breadcrumb />} */}
        </HStack>
        <HStack maxWidth="100%" position="relative">
          {!isSmallDevice && scrollPosition >= 150 ? (
            <Box
              backdropFilter="auto"
              backdropBlur="1px"
              px={2}
              zIndex={5}
              left={0}
              position="absolute"
              borderRightRadius="full"
            >
              <IconButton
                icon={<FaChevronLeft />}
                size="sm"
                onClick={handleClickScrollRight}
                isRound
                shadow="lg"
              />
            </Box>
          ) : null}
          <HStack
            id="filters"
            maxWidth="100%"
            overflowX="scroll"
            style={{ scrollbarWidth: "none" }}
            className="withoutScrollbar"
            mb={3}
          >
            {filters}
          </HStack>
          {!isSmallDevice && scrollPosition < div?.offsetWidth - 400 ? (
            <Box
              backdropFilter="auto"
              backdropBlur="1px"
              px={2}
              zIndex={5}
              right={0}
              position="absolute"
              borderLeftRadius="full"
            >
              <IconButton
                icon={<FaChevronRight />}
                size="sm"
                onClick={handleClickScrollLeft}
                isRound
                shadow="lg"
              />
            </Box>
          ) : null}
        </HStack>
      </Stack>
    );
  }
  return (
    <Stack spacing={3}>
      <HStack justify="space-between" w="100%">
        <Heading fontSize={isSmallDevice ? 20 : 24} fontWeight={500}>
          {title}
        </Heading>
        {buttons ? buttons : null}
      </HStack>
    </Stack>
  );
};
