import Axios from "config/axios";
import { useMutation } from "react-query";
import { useToast } from "components/ui/use-toast";

/**
 * @author Issa Drici <issadrici@gmail.com>
 * @returns
 */
export const useUpdateEleveClasse = ({
  eleveClasseId,
  handleCallbackSuccess,
}) => {
  const { toast } = useToast();

  return useMutation(
    ({ data }) =>
      Axios.put(
        `/eleve-classe/${eleveClasseId ? eleveClasseId : data?.eleveClasse}`,
        data
      ),
    {
      onSuccess: async () => {
        // toastSuccess(toast, { title: "Classe modifié avec succès !" });
        if (handleCallbackSuccess !== undefined) {
          handleCallbackSuccess();
        }
        return true;
      },
      onError: async () => {
        toast({
          variant: "destructive",
          title: "Oh là là ! Quelque chose s'est mal passé.",
          description: "Erreur de modification",
        })
        return false;
      },
    }
  );
};
