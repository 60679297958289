import {
  Box,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card";
import { FcDataBackup, FcDataRecovery } from "react-icons/fc";

const PageWelcomeInscription = () => {
  return (
    <Stack
      width="100vw"
      px={50}
      py={100}
      justify="center"
      align="center"
      spacing={7}
    >
      <Image src="/media/logo-amh-vert.jpg" h={50} alt="logo" />

      <Link href="/inscription" flex={1} w="100%" maxWidth="350px">
        <Card justifyContent="center" align="center">
          <Box bgColor="gray.100" borderRadius="full">
            <Icon as={FcDataRecovery} w="6rem" h="6rem" color="red.500" p={4} />
          </Box>
          <Stack spacing={-1}>
            <Heading size="xl" textTransform="uppercase">
              Inscription
            </Heading>
          </Stack>
        </Card>
      </Link>
      <Link href="/reinscription" flex={1} w="100%" maxWidth="350px">
        <Card justifyContent="center" align="center">
          <Box bgColor="gray.100" borderRadius="full">
            <Icon as={FcDataBackup} w="6rem" h="6rem" color="red.500" p={4} />
          </Box>
          <Stack spacing={-1}>
            <Heading size="xl" textTransform="uppercase">
              Réinscription
            </Heading>
          </Stack>
        </Card>
      </Link>
    </Stack>
  );
};

export default PageWelcomeInscription;
