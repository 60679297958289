import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return Eleves
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindEleves = (params) => {
  const { data, isLoading } = useQuery(
    ["eleves", params?.toString()],
    () => {
      return Axios.get("/eleves", { params });
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const { eleves } = data ?? [];

  return {
    eleves,
    isLoading,
  };
};
