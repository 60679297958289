import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return professeur
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindElevesClasseByEleveId = (eleveId) => {
  const { data, isLoading, isFetching } = useQuery(
    ["elevesClasse-eleve", eleveId],
    () => {
      if (eleveId) {
        return Axios.get(`/eleves-classe/${eleveId}/eleve`);
      }
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const { elevesClasse, canEdit } = data ?? {};

  return {
    elevesClasse,
    canEdit,
    isLoading,
    isFetching,
  };
};
