/* eslint-disable no-unused-vars */
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { getAuth, signOut } from "firebase/auth";
import Axios from "config/axios";
import { QueryCache } from "react-query";

export const AuthentificationContext = createContext();
export const useAuthentificationContext = () =>
  useContext(AuthentificationContext);
export const AuthentificationProvider = (props) => {
  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(null);
  const [isError, setIsError] = useState(false);
  const auth = getAuth();
  const queryCache = new QueryCache();

  const tokenChanged = useCallback(
    () =>
      auth.onIdTokenChanged((userAuth) => {
        if (userAuth) {
          setUser((u) => ({ ...u, token: userAuth.accessToken }));
        }
      }),
    [auth]
  );

  useEffect(() => {
    tokenChanged();
    return () => tokenChanged();
  }, [tokenChanged]);

  const log = useCallback(
    () =>
      auth.onAuthStateChanged(async (userAuth) => {
        if (userAuth) {
          await Axios.get(`${process.env.REACT_APP_HOST_SKAPP_API}/check-auth`)
            .then((response) => {
              setUser(response);
              userAuth
                .getIdToken(true)
                .then((idToken) => {
                  setUser((u) => ({ ...u, token: idToken }));
                })
                .catch((err) => {
                  signOut(auth)
                    .then(() => {
                      setIsError(true);
                      setUser(null);
                      queryCache.clear();
                    })
                    .catch(() => {});
                });
            })
            .catch(() => {
              signOut(auth)
                .then(() => {
                  setIsError(true);
                  setUser(null);
                  queryCache.clear();
                })
                .catch(() => {});
            });
        }
        setInitializing(false);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  useEffect(() => {
    log();
    return () => log();
  }, [log]);

  // remove des queries lors de la déconnection

  const unsubscribe = useCallback(
    () =>
      auth.onAuthStateChanged((userAuth) => {
        if (!userAuth) {
          if (user !== null) {
            signOut(auth)
              .then(() => {
                setUser(null);
              })
              .catch(() => {});
          }
          queryCache.clear();
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, auth]
  );

  useEffect(() => {
    unsubscribe();
    return () => unsubscribe();
  }, [unsubscribe]);

  const logOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        queryCache.clear();
      })
      .catch(() => {});
  };

  const isLogged = user?.roles !== undefined && user?.token !== undefined;
  const isAdmin = user?.roles?.includes("ROLE_ADMIN");
  const isEnseignement = user?.roles?.includes("ROLE_ENSEIGNEMENT");
  const isProfesseur = user?.roles?.includes("ROLE_PROFESSEUR");

  const canAccessAbsences = isAdmin || isEnseignement;
  const canAccessCoursAppel = isAdmin || isEnseignement || isProfesseur;
  const canAccessClasses = isAdmin || isEnseignement || isProfesseur;
  const canAccessEleves = isAdmin || isEnseignement;
  const canAccessInscriptions = isAdmin || isEnseignement;
  

  return (
    <AuthentificationContext.Provider
      value={{
        logOut,
        user,
        setUser,
        isError,
        initializing,
        isLogged,
        isAdmin,
        isEnseignement,
        isProfesseur,
        canAccessAbsences,
        canAccessCoursAppel,
        canAccessClasses,
        canAccessEleves,
        canAccessInscriptions
      }}
      {...props}
    />
  );
};
