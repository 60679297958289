import PageAbsences from "App/absences/pages/PageAbsences";
import PageAbsencesStatistiques from "App/absences/pages/PageAbsencesStatistiques";
import PageAccueil from "App/accueil/pages/PageAccueil";
import PageAppel from "App/appel/pages/PageAppel";
import PageClasse from "App/classe/pages/PageClasse";
import PageListeClasse from "App/classe/pages/PageListeClasse";
import PageCours from "App/cours/pages/PageCours";
import PageEleve from "App/eleve/pages/PageEleve";
import PageListeEleves from "App/eleve/pages/PageListeEleves";
import PageFormulaireInscription from "App/inscription/PageFormulaireInscription";
import PageConfirmInscription from "App/inscription/PageConfirmInscription";
import PageWelcomeInscription from "App/inscription/PageWelcomeInscription";
import Logout from "App/profil/pages/Logout";
import { Authenticated } from "components/Authenticated/Authenticated";
import Page404 from "pages/error/Page404";
import PageUnauthorized from "pages/error/PageUnauthorized";
import { PageLogin } from "pages/login/PageLogin";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PageListeInscriptions from "App/inscription/PageListeInscriptions";
import PageAttributionClasses from "App/eleve/pages/PageAttributionClasses";
import PageListeProfesseur from "App/utilisateur/PageListeProfesseur";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={PageLogin} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/unauthorized" component={PageUnauthorized} />
      <Route exact path="/404" component={Page404} />
      <Route exact path="/" component={() => <Redirect to="/accueil" />} />
      <Route exact path="/inscription" component={PageFormulaireInscription} />
      <Route
        exact
        path="/inscription/:inscriptionId"
        component={PageConfirmInscription}
      />
      <Route exact path="/welcome" component={PageWelcomeInscription} />
      <Authenticated
        exact
        authorisedUsers={["all"]}
        path="/accueil"
        component={PageAccueil}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/absences"
        component={PageAbsences}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/absences/stats"
        component={PageAbsencesStatistiques}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT", "ROLE_PROFESSEUR"]}
        path="/cours"
        component={PageCours}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT", "ROLE_PROFESSEUR"]}
        path="/classes"
        component={PageListeClasse}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT", "ROLE_PROFESSEUR"]}
        path="/classe/:classeId"
        component={PageClasse}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT", "ROLE_PROFESSEUR"]}
        path="/cours/:coursId/appel"
        component={PageAppel}
      />

      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/eleves"
        component={PageListeEleves}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/eleve/:eleveId"
        component={PageEleve}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/inscriptions"
        component={PageListeInscriptions}
      />

      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/attribution-classes"
        component={PageAttributionClasses}
      />
       <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/professeurs"
        component={PageListeProfesseur}
      />
    </Switch>
  );
};

export default Routes;
