import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import * as FontAwesome from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";

const NavItem = (props) => {
  const { navItem } = props;
  const isActive = !!useRouteMatch(navItem?.url);
  const { push } = useHistory();

  return (
    <HStack
      cursor="pointer"
      backgroundColor={isActive ? "brand.50" : null}
      color={isActive ? "brand.500" : '#334155'}
      px={2.5}
      py={1.5}
      borderRadius={5}
      role="group"
      onClick={() => push(navItem?.url)}
    >
      <Box
        _groupHover={{ transform: "translateX(5px)" }}
        transition=".2s ease-out"
        mr="6px"
      >
        {React.createElement(FontAwesome[navItem?.icon])}
      </Box>
      <Text
        fontSize={14}
        fontWeight={isActive ? 700 : 500}
        color={isActive ? "brand.500" : null}
        _groupHover={{ transform: "translateX(5px)" }}
        transition=".1s ease-out"
      >
        {navItem?.label}
      </Text>
    </HStack>
  );
};

export default NavItem;
