import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import FocusLock from "react-focus-lock";

const FilterTextButton = ({ leftIcon, id, label, defaultValue }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const firstFieldRef = React.useRef(null);

  const [value, setValue] = useState("");

  const handleSetParam = () => {
    if (value) {
      params.set(id, value);
      push({ search: params.toString() });
      onClose();
    }
  };

  const handleDeleteParam = (e) => {
    e.preventDefault();

    params.delete(id);
    push({ search: params.toString() });
    setValue("");
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      shadow="lg"
      initialFocusRef={firstFieldRef}
    >
      <PopoverTrigger>
        <Button
          size="xs"
          minW="fit-content"
          variant={params.has(id) ? "solid" : "outline"}
          colorScheme="brand"
          leftIcon={leftIcon}
          rightIcon={
            params.has(id) ? (
              <Icon
                as={MdClose}
                color="white"
                _hover={{ color: "black" }}
                cursor="pointer"
                onClick={handleDeleteParam}
              />
            ) : null
          }
        >
          {params.has(id) ? params.get(id) : label}
        </Button>
      </PopoverTrigger>
      <PopoverContent p={3} shadow="lg">
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <FormControl>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <Input
              id={id}
              ref={firstFieldRef}
              defaultValue={params.has(id) ? params.get(id) : defaultValue}
              onChange={(event) => setValue(event.target.value)}
              value={defaultValue || value}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSetParam();
                }
              }}
            />
          </FormControl>

          <ButtonGroup display="flex" mt={3} justifyContent="flex-end">
            <Button
              size="sm"
              variant="ghost"
              onClick={onClose}
              colorScheme="blackAlpha"
            >
              Annuler
            </Button>
            <Button
              size="sm"
              variant="ghost"
              colorScheme="brand"
              onClick={handleSetParam}
            >
              Valider
            </Button>
          </ButtonGroup>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default FilterTextButton;
