import { Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import React from "react";
import { useParams } from "react-router-dom";
import CardClasses from "../components/CardClasses";
import { useFindEleveById } from "../services/useFindEleveById";
import { useFindElevesClasseByEleveId } from "../services/useFindElevesClasseByEleveId";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useFindEleveResponsableLegauxByFilters } from "App/responsable-legaux/services/useFindEleveResponsableLegauxByFilters";
import { useFindInscriptionByFilters } from "App/inscription/services/useFindInscriptionByFilters";
import { useFindPaiementByFilters } from "App/paiement/services/useFindPaiementByFilters";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarLabel,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from "components/ui/menubar";
import {
  ArchiveIcon,
  MobileIcon,
  PaperPlaneIcon,
  Pencil1Icon,
  PlusIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import CardResponsableLegal from "App/responsable-legaux/components/CardResponsableLegal";
import { useState } from "react";
import DialogEleve from "../components/DialogEleve";
import DialogResponsableLegal from "App/responsable-legaux/components/DialogResponsableLegal";
import DialogEleveClasses from "App/classe/components/DialogEleveClasses";
import ModalCreateClasseEleveClasse from "../components/ModalCreateClasseEleveClasse";

const PageEleve = () => {
  const { eleveId } = useParams();
  const [openDialogEleve, setOpenDialogEleve] = useState(false);
  const [openDialogResponsableLegal, setOpenDialogResponsableLegal] =
    useState(false);
  const [openDialogClasses, setOpenDialogClasses] = useState(false);
  const [responsableSelected, setResponsableSelected] = useState();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  params?.set("filter[eleve][id]", eleveId);

  const {
    eleve,
    canEdit: canEditEleve,
    isLoading: isLoadingEleve,
  } = useFindEleveById(eleveId);

  const {
    onOpen: onOpenAddClasse,
    isOpen: isOpenAddClasse,
    onClose: onCloseAddClasse,
  } = useDisclosure();

  const {
    elevesClasse,
    canEdit: canEditElevesClasse,
    isLoading: isLoadingElevesClasse,
  } = useFindElevesClasseByEleveId(eleveId);

  const { eleveResponsableLegaux, isLoading: isLoadingEleveResponsableLegaux } =
    useFindEleveResponsableLegauxByFilters(params);

  const { inscription, isLoading: isLoadingInscription } =
    useFindInscriptionByFilters(params);

  if (!isLoadingInscription) {
    params?.set("filter[inscription][id]", inscription && inscription[0]?.id);
  }

  const { paiement, isLoading: isLoadingPaiement } =
    useFindPaiementByFilters(params);

  const handleOpenDialogResponsable = (responsable) => {
    setResponsableSelected(responsable);
    setOpenDialogResponsableLegal(true);
  };

  const handleSetOpenDialogResponsable = (op) => {
    if (!op) {
      setResponsableSelected(null);
    }
    setOpenDialogResponsableLegal(op);
  };
  return (
    <LayoutDefault
      isLoading={
        isLoadingEleve ||
        isLoadingElevesClasse ||
        isLoadingEleveResponsableLegaux ||
        isLoadingInscription ||
        isLoadingPaiement
      }
      dataAvailable={
        !!eleve &&
        !!elevesClasse &&
        !!eleveResponsableLegaux &&
        !!inscription &&
        !!paiement
      }
    >
      <div className="flex justify-between">
        <div className="flex items-end gap-2">
          <p className="text-xl font-bold">
            {eleve?.prenom} {eleve?.nom}
          </p>
          <p className="text-sm text-slate-500 leading-6">
            {eleve?.dateNaissance}
          </p>
        </div>
        <Menubar>
          <MenubarMenu>
            <MenubarTrigger>Élève</MenubarTrigger>
            <MenubarContent>
              <MenubarItem onClick={() => setOpenDialogEleve(true)}>
                Modifier{" "}
                <MenubarShortcut>
                  <Pencil1Icon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </MenubarItem>
              <MenubarItem onClick={() => setOpenDialogEleve(true)}>
                Archiver{" "}
                <MenubarShortcut>
                  <ArchiveIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>Responsables Légaux</MenubarTrigger>
            <MenubarContent>
              {eleveResponsableLegaux?.map((responsable, index) => {
                return (
                  <>
                    <MenubarLabel>
                      {responsable?.responsableLegaux?.prenom}{" "}
                      {responsable?.responsableLegaux?.nom}
                    </MenubarLabel>
                    <MenubarSeparator />
                    <MenubarItem
                      onClick={() =>
                        handleOpenDialogResponsable(
                          responsable?.responsableLegaux
                        )
                      }
                    >
                      Modifier{" "}
                      <MenubarShortcut>
                        <Pencil1Icon className="mr-2 h-4 w-4" />
                      </MenubarShortcut>
                    </MenubarItem>
                    <a href={`mailto:${responsable?.responsableLegaux?.email}`}>
                      <MenubarItem>
                        Envoyer un email{" "}
                        <MenubarShortcut>
                          <PaperPlaneIcon className="mr-2 h-4 w-4" />
                        </MenubarShortcut>
                      </MenubarItem>
                    </a>
                    <a
                      href={`tel:${responsable?.responsableLegaux?.telephone}`}
                    >
                      <MenubarItem>
                        Appeler{" "}
                        <MenubarShortcut>
                          <MobileIcon className="mr-2 h-4 w-4" />
                        </MenubarShortcut>
                      </MenubarItem>
                    </a>
                    {index !== eleveResponsableLegaux?.length - 1 ? (
                      <MenubarSeparator />
                    ) : null}
                  </>
                );
              })}
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>Classes</MenubarTrigger>
            <MenubarContent>
              <MenubarItem onClick={onOpenAddClasse}>
                Ajouter{" "}
                <MenubarShortcut>
                  <PlusIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </MenubarItem>
              <MenubarItem onClick={() => setOpenDialogClasses(true)}>
                Supprimer{" "}
                <MenubarShortcut>
                  <TrashIcon className="mr-2 h-4 w-4" />
                </MenubarShortcut>
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
        </Menubar>
      </div>
      <Grid
        templateAreas={{
          base: `
          "cardEleve"
          "cardResponsable"
          "cardResponsable"
          "cardClasses"
          "cardInscription"
          "cardPaiements"
          "cardCommentaires"`,
          lg: `
          "cardResponsable1 cardResponsable1 cardResponsable1 cardResponsable1 cardResponsable2 cardResponsable2 cardResponsable2 cardResponsable2 cardClasses cardClasses"`,
          // lg: `
          // "cardResponsable1 cardResponsable1 cardResponsable1 cardResponsable1 cardResponsable2 cardResponsable2 cardResponsable2 cardResponsable2 cardClasses cardClasses"
          // ". . . . cardInscription cardInscription cardInscription cardInscription cardInscription cardInscription"
          // "cardPaiements cardPaiements cardPaiements cardPaiements cardPaiements cardCommentaires cardCommentaires cardCommentaires cardCommentaires cardCommentaires"
          // "cardPaiements cardPaiements cardPaiements cardPaiements cardPaiements cardCommentaires cardCommentaires cardCommentaires cardCommentaires cardCommentaires"`,
        }}
        gridTemplateRows={{ base: "repeat(7, 1fr)", lg: "repeat(1, 1fr)" }}
        gridTemplateColumns={{
          base: "repeat(1, 1fr)",
          lg: "repeat(10, 1fr)",
        }}
        gap="3"
      >
        {/* <GridItem gridArea="cardEleve">
          <CardEleve
            eleve={eleve}
            inscription={inscription ? inscription[0] : null}
            canEdit={canEditEleve}
          />
        </GridItem> */}
        <GridItem gridArea="cardClasses">
          <CardClasses
            eleve={eleve}
            classes={elevesClasse}
            canEdit={canEditElevesClasse}
            setOpenDialogClasses={setOpenDialogClasses}
          />
        </GridItem>
        {/* <GridItem gridArea="cardInscription">
          <CardInscription inscription={inscription ? inscription[0] : null} />
        </GridItem> */}
        {eleveResponsableLegaux?.map((responsable, index) => {
          return (
            <GridItem gridArea={`cardResponsable${index + 1}`} key={index}>
              <CardResponsableLegal responsableLegal={responsable} />
            </GridItem>
          );
        })}
        {/* <GridItem gridArea="cardCommentaires">
          <CardCommentairesEleve />
          </GridItem>
          <GridItem gridArea="cardPaiements">
          <CardPaiements paiement={paiement} />
        </GridItem> */}
      </Grid>
      <DialogEleve
        eleve={eleve}
        open={openDialogEleve}
        setOpen={setOpenDialogEleve}
      />
      <DialogEleveClasses
        eleveClasses={elevesClasse}
        open={openDialogClasses}
        setOpen={setOpenDialogClasses}
      />
      <ModalCreateClasseEleveClasse
        eleve={eleve}
        isOpen={isOpenAddClasse}
        onClose={onCloseAddClasse}
      />
      {responsableSelected ? (
        <DialogResponsableLegal
          responsable={responsableSelected}
          open={openDialogResponsableLegal}
          setOpen={handleSetOpenDialogResponsable}
        />
      ) : null}
    </LayoutDefault>
  );
};

export default PageEleve;
