import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useMenuAdmin } from "./useMenuAdmin";
import ListNav from "./_partials/ListNav";
import { FaBars, FaCertificate } from "react-icons/fa";

const NavbarMobile = () => {
  const { NavAdmin } = useMenuAdmin();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Icon
        as={FaBars}
        onClick={onOpen}
        w={5}
        h={5}
        color="#334155"
        cursor="pointer"
      />
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          maxWidth="13rem"
          shadow={
            "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0 ,0 ,0 , 0.19) 0px 6px 20px 0px"
          }
        >
          <DrawerBody p={0}>
            <HStack w="100%" justify="center" py={3}>
              <Icon as={FaCertificate} color="#75ed51" />
              <Text fontWeight={700} fontSize={12}>
                Assoc. Musulmans du Havre
              </Text>
            </HStack>
            <Stack spacing={3}>
              {NavAdmin?.map((item) => {
                return <ListNav key={item?.title} catNav={item} />;
              })}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NavbarMobile;
