import Axios from "config/axios";
import { useMutation } from "react-query";
import { useToast } from "components/ui/use-toast";

/**
 * @author Issa Drici <issadrici@gmail.com>
 * @returns
 */
export const useReinscriptionEleve = ({ handleCallbackSuccess }) => {
  const { toast } = useToast();

  return useMutation(( data ) => Axios.post(`/reinscription`, data), {
    onSuccess: (inscription) => {

      if (handleCallbackSuccess !== undefined) {
        handleCallbackSuccess(inscription);
      }
      return true;
    },
    onError: (error, variables, context) => {
      toast({
        variant: "destructive",
        title: "Oh là là ! Quelque chose s'est mal passé.",
        description: error.response.data.detail,
      })
      return false;
    },
  });
};
