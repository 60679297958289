import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return eleve
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindEleveById = (eleveId) => {
  const { data, isLoading, isFetching } = useQuery(
    ["eleve", eleveId],
    () => {
      if (eleveId) {
        return Axios.get(`/eleve/${eleveId}`);
      }
    },
    { retry: false, refetchOnWindowFocus: true }
  );
  
  const { eleve, canEdit } = data ?? {};

  return {
    eleve,
    canEdit,
    isLoading,
    isFetching,
  };
};
