import React from "react";
import { Box, Image } from "@chakra-ui/react";

export const LayoutLogin = ({ children, width }) => {
  return (
    <Box
      minW="100vw"
      minH="100vh"
      bgColor="#f1f5f9"
      // background="linear-gradient(120deg, #FAF8D4 0%, #B2A3B5 100%)"
      // // backgroundImage="url(/media/background_login.jpg)"
      // backgroundSize="cover"
      // backgroundRepeat="no-repeat"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      {/* <Image src="/logo.png" w={150} mb={5} alt="logo" /> */}
      <Box
        p={10}
        color="gray.500"
        boxShadow="lg"
        border="1px solid #e2e8f0"
        borderRadius="md"
        fontWeight="semibold"
        letterSpacing="wide"
        fontSize="xs"
        backgroundColor="white"
        maxW="full"
      >

         {children}
      </Box>
    </Box>
  );
};
