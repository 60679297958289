import { useDisclosure } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import { useState } from "react";
import ModalCreateClasseEleveClasse from "./ModalCreateClasseEleveClasse";
import Button from "components/button/Button";

const CardClasses = (props) => {
  const { eleve, classes, setOpenDialogClasses } = props;

  

  const canEdit = true;

  const checkConflicts = () => {};

  return (
    <>
      <Card
        classCard="relative h-full bg-primary"
        classContent={`px-2 pt-8 h-full flex flex-col items-center ${
          classes?.length > 1 ? "pb-0" : "pb-3"
        }`}
      >
        <p className="absolute top-1 left-3 font-bold text-sm text-secondary">
          {classes[0]?.classe?.etablissement?.nom}
        </p>
        <p className="absolute top-2 right-3 font-medium text-xs text-secondary">
          {`${classes?.length} attribution${classes?.length > 1 ? "s" : ""}`}
        </p>
        <div className="w-full h-full bg-gradient-to-r from-green-400 to-white rounded-lg flex justify-center items-center">
          <p className="text-3xl font-bold text-slate-600">
            {classes[0]?.classe?.nom}
          </p>
        </div>
        {classes?.length > 1 ? (
          <Button
            variant="ghost"
            className="h-fit px-1 py-0.5 text-xs my-1 text-secondary w-fit"
            onClick={() => setOpenDialogClasses(true)}
          >
            Voir +
          </Button>
        ) : null}
      </Card>
      
    </>
  );
};

export default CardClasses;
