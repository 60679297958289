import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { LayoutDefault } from "App/layout/LayoutDefault";
import Card from "components/card/Card";
import React, { useCallback } from "react";
import DataTable from "react-data-table-component";
import { useHandleDownload } from "services/files/files";
import { useFindEleves } from "../services/useFindEleves";
import {
  AiFillExclamationCircle,
  AiOutlineCloudDownload,
  AiOutlinePlus,
} from "react-icons/ai";
import LoadingTable from "components/loading/LoadingTable";
import { FiPhoneCall, FiSend } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import ModalCreateEleve from "../components/ModalCreateEleve";
// import qs from "qs";
import { useAuthentificationContext } from "context/auth-context";
import { PageHeader } from "App/layout/PageHeader";
// import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
// import { normalizeForm } from "functions/formulaire/normalizeForm";
// import DrawerEleves from "../components/DrawerEleves";
import FilterTextButton from "components/filter/filter-text/FilterTextButton";

const PageListeEleves = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const {
    onOpen: onOpenModalCreateEleve,
    isOpen: isOpenModalCreateEleve,
    onClose: onCloseModalCreateEleve,
  } = useDisclosure();

  // const {
  //   onOpen: onOpenDrawerFilter,
  //   isOpen: isOpenDrawerFilter,
  //   onClose: onCloseDrawerFilter,
  // } = useDisclosure();

  const { eleves, isLoading } = useFindEleves(params);

  const { isAdmin, isEnseignement } = useAuthentificationContext();

  const { download, isLoading: isLoadingExtract } = useHandleDownload();

  const { toast } = useToast();

  const columns = [
    {
      name: "Nom",
      selector: (row) => row?.eleve?.nom,
      sortable: true,
    },
    {
      name: "Prénom",
      selector: (row) => row?.eleve?.prenom,
      sortable: true,
    },
    // {
    //   name: "Téléphone",
    //   selector: (row) => row?.eleve?.telephone,
    // },
    // {
    //   name: "Email",
    //   selector: (row) => row?.eleve?.mail,
    // },
    {
      grow: 2,
      name: "Classes",
      selector: (row) => row?.classes[0]?.nom,
      cell: (row) => (
        <HStack>
          {row?.classes?.map((classe) => (
            <Badge
              key={classe?.id}
              colorScheme={
                classe?.etablissement?.nom === "CE" ? "blue" : "green"
              }
            >
              {classe?.nom}
            </Badge>
          ))}
        </HStack>
      ),
    },
    {
      button: true,
      cell: (row) => (
        <HStack>
          <IconButton
            aria-label="Call"
            icon={<FiPhoneCall />}
            href={`tel:${row?.eleve?.telephone}`}
            as="a"
          />
          <IconButton
            aria-label="Send Message"
            icon={<FiSend />}
            href={`sms:${row?.eleve?.telephone}`}
            as="a"
          />
        </HStack>
      ),
    },
  ];

  // const handleFilters = async (values) => {
  //   const { filter } = values;
  //   const payload = normalizeForm(filter);
  //   const filtered = Object.fromEntries(
  //     Object.entries(payload)?.filter(
  //       (a) => a[1] !== "null" && a[1] !== null && a[1] !== ""
  //     )
  //   );
  //   const str = qs.stringify({ filter: filtered });
  //   push({ search: str });
  //   onCloseDrawerFilter();
  // };

  const handleExtract = useCallback(async () => {
    try {
      // Start loading extract
      await download(
        `${process.env.REACT_APP_HOST_SKAPP_API}/eleves/pdf/download`
      );
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Oh là là ! Quelque chose s'est mal passé.",
        description: "app:core.errors.extract_title",
      })
    }
  }, [download, toast]);

  return (
    <LayoutDefault>
      <Stack p={5} pt={0}>
        <PageHeader
          title="Élèves"
          filters={
            <>
              <FilterTextButton id="filter[nom]" label="Nom" />
              <FilterTextButton id="filter[prenom]" label="Prénom" />
            </>
          }
        >
          <HStack flex={{ base: 1, xl: 0 }} spacing={1} justify="flex-end">
            {(isAdmin || isEnseignement) && (
              <>
                <Tooltip label="Exporter">
                  <IconButton
                    onClick={() => handleExtract()}
                    aria-label="Extract"
                    icon={<AiOutlineCloudDownload />}
                    isLoading={isLoadingExtract}
                  />
                </Tooltip>
                <Tooltip label="Ajouter">
                  <IconButton
                    onClick={onOpenModalCreateEleve}
                    aria-label="Ajout"
                    icon={<AiOutlinePlus />}
                    // isLoading={isLoadingExtract}
                  />
                </Tooltip>
                {/* <Tooltip label="Réinitialiser">
                  <IconButton
                    onClick={() => push("/eleves")}
                    aria-label="Reset"
                    icon={<RiFilterOffFill />}
                    // isLoading={isLoadingExtract}
                  />
                </Tooltip>
                <Tooltip label="Filtrer">
                  <IconButton
                    onClick={onOpenDrawerFilter}
                    aria-label="Filter"
                    icon={<RiFilterFill />}
                    // isLoading={isLoadingExtract}
                  />
                </Tooltip> */}
              </>
            )}
          </HStack>
        </PageHeader>
        <Card p={0} maxWidth="100%">
          <DataTable
            columns={columns}
            data={eleves}
            progressPending={isLoading}
            progressComponent={<LoadingTable />}
            highlightOnHover
            striped
            pagination
            pointerOnHover
            noDataComponent={
              <HStack mb={5}>
                <Icon
                  as={AiFillExclamationCircle}
                  w={5}
                  h={5}
                  color="red.500"
                />
                <Text>
                  Aucun élève n'existe... Veuillez en créer une en cliquant sur
                  le bouton au dessus
                </Text>
              </HStack>
            }
            // paginationResetDefaultPage={resetPaginationToggle}
            // subHeaderComponent={subHeaderComponentMemo}
            paginationComponentOptions={{
              rangeSeparatorText: "sur",
              rowsPerPageText: "Lignes par page",
            }}
            onRowClicked={(item) => push(`/eleve/${item?.eleve?.id}`)}
          />
        </Card>
      </Stack>
      <ModalCreateEleve
        isOpen={isOpenModalCreateEleve}
        onClose={onCloseModalCreateEleve}
      />
      {/* <DrawerEleves
        isOpenDrawerFilter={isOpenDrawerFilter}
        onCloseDrawerFilter={onCloseDrawerFilter}
        handleSubmit={(values) => handleFilters(values)}
      /> */}
    </LayoutDefault>
  );
};

export default PageListeEleves;
