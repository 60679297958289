import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return Eleves
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindAbsencesDataPieChart = () => {
  const { data, isLoading } = useQuery("data-absences-pie-chart", () => {
    return Axios.get("/presences-cours/pie-chart");
  });

  const { dataPieChart } = data ?? [];

  return {
    dataPieChart,
    isLoading,
  };
};
