import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return professeur
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindElevesByCoursId = (coursId) => {
  const { data, isLoading, isFetching } = useQuery(["elevesClasse-cours", coursId], () => {
    if (coursId) {
      return Axios.get(`/eleves/${coursId}/cours`);
    }
  },
  { retry: false, refetchOnWindowFocus: true });

  const elevesClasse = data?.elevesClasse;

  return {
    elevesClasse,
    isLoading,
    isFetching,
  };
};
