import { HStack, Icon, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useMenuAdmin } from "./useMenuAdmin";
import ListNav from "./_partials/ListNav";
import { FaCertificate } from "react-icons/fa";

const NavbarDefault = () => {
  const { NavAdmin } = useMenuAdmin();

  return (
    <Stack
      w="16rem"
      minHeight="100vh"
      height="100%"
      flex={1}
      position="fixed"
      bgColor="#f8fafc"
      transition="all .7s ease"
      zIndex={5}
      borderRightWidth="1px"
      borderRightStyle="solid"
      borderRightColor="#e2e8f0"
      alignSelf="stretch"
    >
      <HStack w="100%" justify="center" py={3}>
        <Icon as={FaCertificate} color="#75ed51" />
        <Text fontWeight={700} fontSize={12}>Assoc. Musulmans du Havre</Text>
      </HStack>
      <Stack spacing={3}>
        {NavAdmin?.map((item) => {
          return <ListNav key={item?.title} catNav={item} />;
        })}
      </Stack>
    </Stack>
  );
};

export default NavbarDefault;
