import { Box, Heading, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { useFindInscriptionByFilters } from "./services/useFindInscriptionByFilters";
// import { useState } from "react";

const PageConfirmInscription = () => {
  const { inscriptionId } = useParams();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  params?.set("filter[inscription][id]", inscriptionId);

  const { inscription, isLoading: isLoadingInscription } =
    useFindInscriptionByFilters(params);

  return (
    <HStack
      minH="100vh"
      height="100%"
      maxWidth="100vw"
      width="100%"
      align="flex-start"
      spacing={0}
    >
      <Stack
        w="100%"
        maxW="100vw"
        h="100%"
        minH="100vh"
        spacing={0}
        overflow="hidden"
        position="relative"
        align="center"
      >
        <Stack align="center" maxWidth={{ base: "90vw", md: "40vw" }} pt={50}>
          <Box mb={5}>
            <Image src="/media/logo-amh-vert.jpg" h={50} alt="logo" />
          </Box>
          {!isLoadingInscription ? (
            <Stack pb={5} w="100%">
              <Heading size="md" textAlign="center">
                La pré-inscription de {inscription[0]?.eleve?.nom}{" "}
                {inscription[0]?.eleve?.prenom} a bien été prise en compte
              </Heading>

              <Text>
                Un mail vient de vous être adressé avec le dossier pré-rempli.
              </Text>
              <Text fontWeight={700}>
                Si le message n'apparaît pas dans votre boîte de réception,
                recherchez le dans vos indésirables/spam.
              </Text>
              <Text>
                Afin de finaliser votre inscription, veuillez nous ramener les
                pièces justificatives lors des permanences le{" "}
                <span style={{ fontWeight: 700 }}>
                  mardi de 13:00 à 18:00 au Centre Essalam
                </span>{" "}
                et le{" "}
                <span style={{ fontWeight: 700 }}>
                  vendredi de 12:00 à 17:00 au CIH
                </span>.
              </Text>
              <Text>
                La cotisation doit être réglée <span style={{ fontWeight: 700 }}>avant le 2 juillet 2023</span>.
              </Text>
            </Stack>
          ) : null}
          <Stack></Stack>
        </Stack>
      </Stack>
    </HStack>
  );
};

export default PageConfirmInscription;
