import { useAuthentificationContext } from "context/auth-context";
import dayjs from "dayjs";

export const useMenuAdmin = () => {
  const {
    canAccessCoursAppel,
    canAccessAbsences,
    canAccessClasses,
    canAccessEleves,
    canAccessInscriptions,
  } = useAuthentificationContext();

  const NavAdmin = [
    {
      title: "",
      links: [
        {
          label: "Accueil",
          icon: "FaHome",
          url: "/accueil",
          canAccess: true,
        },
      ],
    },
    {
      title: "GÉNÉRAL",
      links: [
        {
          label: "Cours du jour",
          icon: "FaBuilding",
          url: "/cours",
          canAccess: canAccessCoursAppel,
        },
        {
          label: "Absences",
          icon: "FaPortrait",
          url: `/absences?filter[date][du]=${dayjs().format(
            "YYYY-MM-01"
          )}&filter[date][au]=${dayjs().format(
            `YYYY-MM-${dayjs().daysInMonth()}`
          )}`,
          canAccess: canAccessAbsences,
        },
      ],
    },
    {
      title: "GESTION",
      links: [
        {
          label: "Classes",
          icon: "FaFolder",
          url: "/classes",
          canAccess: canAccessClasses,
        },
        {
          label: "Professeurs",
          icon: "FaFolder",
          url: "/professeurs?filter[role]=ROLE_PROFESSEUR",
          canAccess: canAccessAbsences,
        },
        {
          label: "Élèves",
          icon: "FaUserFriends",
          url: "/eleves",
          canAccess: canAccessEleves,
        },
        {
          label: "Inscriptions",
          icon: "FaFolderPlus",
          url: "/inscriptions",
          canAccess: canAccessInscriptions,
        },
        {
          label: "Attribution des classes",
          icon: "FaRegListAlt",
          url: "/attribution-classes",
          canAccess: canAccessInscriptions,
        },
      ],
    },
  ];

  return {
    NavAdmin,
  };
};
