import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return professeur
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindCoursById = (coursId) => {
  const { data, isLoading, isFetching } = useQuery(
    ["cours", coursId],
    () => {
      if (coursId) {
        return Axios.get(`/cours/${coursId}`);
      }
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const cours = data?.cours;

  return {
    cours,
    isLoading,
    isFetching,
  };
};
