import { ReloadIcon } from "@radix-ui/react-icons";
import { Button as ButtonShadcn } from "components/ui/button";
import React from "react";

const Button = (props) => {
  const { children, isLoading, ...rest } = props;

  if (isLoading) {
    return (
      <ButtonShadcn {...rest} disabled>
        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
        Chargement
      </ButtonShadcn>
    );
  }
  return <ButtonShadcn {...rest}>{children}</ButtonShadcn>;
};

export default Button;
