import {
  Heading,
  HStack,
  Icon,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import { useHistory } from "react-router-dom";
import { IoIosPeople } from "react-icons/io";

const CardClassesDashboard = (props) => {
  const { countClasses } = props;

  const { push } = useHistory();

  const countTotal = countClasses?.find((item) => item?.label === "TOTAL");
  const countEtablissements = countClasses?.filter(
    (item) => item?.label !== "TOTAL"
  );

  return (
    <Card classContent="p-4" height="100%" cursor="pointer" onClick={() => push("/classes")} spacing={3} >
      <HStack justify="center" spacing={{ base: 3, lg: 5 }}>
        <Icon
          as={IoIosPeople}
          w="4rem"
          h="4rem"
          color="green.500"
          bgColor="gray.100"
          p={2}
          borderRadius="full"
        />
        <Stack spacing={-1}>
          <Heading size="xl" textTransform="uppercase">
            {countTotal?.count}
          </Heading>
          <Text fontSize={12}>Classes</Text>
        </Stack>
      </HStack>
      <HStack
        spacing={5}
        justify="center"
        divider={<StackDivider borderColor="gray.200" />}
      >
        {countEtablissements?.map((item) => (
          <Stack spacing={0} key={item?.label}>
            <Heading size="sm" textTransform="uppercase">
              {item?.count}
            </Heading>
            <Text fontSize={12}>{item?.label}</Text>
          </Stack>
        ))}
      </HStack>
    </Card>
  );
};

export default CardClassesDashboard;
