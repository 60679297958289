import React, { useState } from "react";
import { Button, Stack, Text, useToast } from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { isEmail, isMinLength } from "@formiz/validations";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FieldInput } from "components/fields/FieldInput";
import PropTypes from "prop-types";
import { toastError } from "functions/toast";
import { useLocation } from "react-router-dom";
import { FieldPassword } from "components/fields/FieldPassword";
import { useGlobalEvents } from "hooks/useGlobalEvents";

export const FormLogin = ({ setIsReseting, setIsErrorLogin }) => {
  const loginForm = useForm({
    subscribe: "form",
  });

  const { toast } = useToast();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickResetPassword = () => setIsReseting(true);
  const auth = getAuth();

  useGlobalEvents({
    form: loginForm,
    type: "keypress",
    key: "Enter",
    action: "submitForm",
  });

  const handleConnexion = (values) => {
    setIsLoading(true);
    signInWithEmailAndPassword(
      auth,
      values.email.toLowerCase(),
      values.password
    )
      .then(() => {})
      .catch(async (error) => {
        await signOut(auth)
          .then(() => {
            toast({
              variant: "destructive",
              title: "Oh là là ! Quelque chose s'est mal passé.",
              description: "Connexion échouée, impossible de se connecter",
            })
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      });
  };

  return (
    <Stack
      spacing={0}
      // minW={{ base: "80vw", sm: "50vw", md: "40vw", xl: "20vw" }}
      w={{base: "80vw", sm: 430}}
    >
      <Text fontSize={24} fontWeight={600} mb="32px">Connexion à votre compte</Text>

      <Stack w="100%">
        <Formiz connect={loginForm} onValidSubmit={handleConnexion}>
          <form noValidate onSubmit={loginForm.submit}>
            <Stack spacing="6" w="100%">
              {/* Formulaire de login */}
              <FieldInput
                w="100%"
                name="email"
                label="Adresse e-mail"
                required="Email requis"
                placeholder="Adresse e-mail"
                defaultValue={params.get("email") ?? ""}
                validations={[
                  {
                    rule: isEmail(),
                    message: "Vérifier le format de votre adresse email",
                  },
                ]}
              />

              {/* Formulaire du mot de passe  */}
              <FieldPassword
                w="100%"
                type="password"
                name="password"
                label="Mot de passe"
                required="Mot de passe requis"
                validations={[
                  {
                    rule: isMinLength(6),
                    message:
                      "Le mot de passe doit contenir au moins 6 caractères",
                  },
                ]}
              />

              <Button
                isLoading={isLoading}
                type="button"
                onClick={() => loginForm.submit()}
                colorScheme="twitter"
                w="100%"
                fontSize={14}
                fontWeight={700}
              >
                Connexion
              </Button>
            </Stack>
          </form>
        </Formiz>
      </Stack>
      <Text
        as="button"
        my={2}
        color="gray.900"
        textAlign="left"
        fontSize="0.8rem"
        fontStyle="italic"
        textDecoration="underline"
        onClick={handleClickResetPassword}
        w="100%"
      >
        Réinitialiser le mot de passe
      </Text>
    </Stack>
  );
};

FormLogin.propTypes = {
  setIsReseting: PropTypes.func.isRequired,
  setIsErrorLogin: PropTypes.func.isRequired,
};
