import { useToast } from "components/ui/use-toast";
import Axios from "config/axios";
import { getCurrentUser } from "config/firebase";
import dayjs from "dayjs";
import { toastError } from "functions/toast";
import React from "react";

export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const useHandleDownload = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({ isError: false });
  const { toast } = useToast();

  const download = async (url) => {
    try {
      setLoading(true);
      let fileName = "file_unamed";
      let options = {
        responseType: "blob",
      };
      const currentUser = getCurrentUser();
      if (currentUser) {
        const tokenResult = await currentUser.getIdTokenResult(false);
        const tokenExpiration = new dayjs(tokenResult.expirationTime);
        const dateExpiration = new dayjs();
        const jwt = await currentUser.getIdToken(
          tokenExpiration <= dateExpiration
        );
        options = {
          ...options,
          headers: {
            Authorization: jwt,
          },
        };
      }

      const instance = Axios.create();
      const { headers, data: blobData } = await instance.get(url, options);
      const contentDisposition = headers?.["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);

      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }

      if (blobData) {
        const blobUrl = window.URL.createObjectURL(blobData);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;
        setError({ isError: false, message: undefined });

        setLoading(false);
        return link.click();
      }
      return null;
    } catch (err) {
      console.warn(err);
      // Gestion des erreurs récupération fichier
      let errorMessage = "Erreur lors de la récupération du fichier.";
      if (err?.response?.status === 404) {
        errorMessage = "Fichier introuvable";
      }
      setLoading(false);
      return toast({
        variant: "destructive",
        title: "Oh là là ! Quelque chose s'est mal passé.",
        description: errorMessage,
      });
    }
  };

  return {
    download,
    isLoading,
    isError: error?.isError,
    errorMessage: error?.message,
  };
};
