import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return classes
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindAllClasses = () => {
  const { data, isLoading, isFetching } = useQuery(
    "classes",
    () => {
      return Axios.get(`/classes`);
    },
    { retry: false, refetchOnWindowFocus: true,  }
  );

  const classes = data?.classes;

  return {
    classes,
    isLoading,
    isFetching,
  };
};
