import {
  AccordionButton,
  AccordionIcon,
  AccordionItem as AccordionItemChakra,
  AccordionPanel,
  Badge,
  Box,
  Heading,
} from "@chakra-ui/react";
import React from "react";

const AccordionItem = (props) => {
  const { children, label, bgColor, color, countChildren } = props;
  return (
    <AccordionItemChakra border="none" mb={1}>
      <h2>
        <AccordionButton
          bgColor={bgColor ?? "white"}
          borderWidth={1.5}
          borderRadius="md"
        >
          <Box flex="1" textAlign="left">
            <Heading size="sm" color={color ?? "inherit"}>
              {label}
            </Heading>
          </Box>
          {countChildren && (
            <Badge variant="solid" colorScheme="red" borderRadius="full" px={1.5} py={0.5}>
              {countChildren}
            </Badge>
          )}
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel px={1} pb={4}>
        {children}
      </AccordionPanel>
    </AccordionItemChakra>
  );
};

export default AccordionItem;
