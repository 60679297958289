import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
// import { useFindAllAnneesScolaire } from "App/annee-scolaire/services/useFindAllAnneesScolaire";
// import { useFindAllClasses } from "App/classe/services/useFindAllClasses";
import React from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useCreateEleve } from "../services/useCreateEleve";
import FormulaireEleve from "./FormulaireEleve";

export default function ModalCreateEleve(props) {
  const { isOpen, onClose } = props;
  const { push } = useHistory();

  const queryClient = useQueryClient();
  const formEleve = useForm({ subscribe: "form" });

  // const [classeId, setClasseId] = useState();
  // const [anneeScolaireId, setAnneeScolaireId] = useState();

  // const { classes, isLoading: isLoadingClasses } = useFindAllClasses();
  // const { anneesScolaire, isLoading: isLoadingAnneesScolaire } =
  //   useFindAllAnneesScolaire();

  const handleCallbackSuccess = async (eleveCreated) => {
    await queryClient.invalidateQueries("eleves");
    onClose();
    push(`/eleve/${eleveCreated?.eleve?.id}`);
  };

  const { mutate: createEleve, isLoading: isLoadingCreateEleve } =
    useCreateEleve({
      handleCallbackSuccess,
    });

  const handleSubmit = (values) => {
    const data = {
      data: {
        ...values,
      },
    };

    createEleve(data);
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="90vw" w="fit-content">
        <Formiz autoform connect={formEleve} onValidSubmit={handleSubmit}>
          <ModalHeader>Ajouter un élève</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* {!isLoadingClasses && !isLoadingAnneesScolaire && ( */}
            <FormulaireEleve />
            {/* )} */}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
            <Button
              colorScheme="blue"
              onClick={formEleve.submit}
              isLoading={isLoadingCreateEleve}
            >
              Ajouter
            </Button>
          </ModalFooter>
        </Formiz>
      </ModalContent>
    </Modal>
  );
}
