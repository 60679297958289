import {
  Button,
  ButtonGroup,
  Divider,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Wrap,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import FocusLock from "react-focus-lock";
import { getMonths } from "functions/common-scope/Dates";
import { useFindAllAnneesScolaire } from "App/annee-scolaire/services/useFindAllAnneesScolaire";
import dayjs from "dayjs";

const FilterMonthButton = ({ leftIcon, id, label, defaultValue }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [month, setMonth] = useState(
    getMonths()[parseInt(params.get("filter[date][du]").substring(5, 7)) - 1]
  );
  const [year, setYear] = useState(
    params.get("filter[date][du]").substring(0, 4)
  );

  const { anneesScolaire, isLoading: isLoadingAnneesScolaire } =
    useFindAllAnneesScolaire();

  const getYears = () => {
    let annees = [];
    if (!isLoadingAnneesScolaire) {
      anneesScolaire.forEach((anneeSco) => {
        const firstYear = anneeSco.label.substring(0, 4);
        const secondYear = anneeSco.label.substring(5, 9);
        if (!annees.includes(firstYear)) {
          annees.push(firstYear);
        }
        if (!annees.includes(secondYear)) {
          annees.push(secondYear);
        }
      });
    }
    return annees;
  };

  const handleSetParam = () => {
    const startDate = `${year}-${(getMonths()?.indexOf(month) + 1)
      .toString()
      .padStart(2, "0")}-01`;

    const endDate = `${startDate.substring(0, 8)}${dayjs(
      startDate
    ).daysInMonth()}`;

    params.set(id + "[du]", startDate);
    params.set(id + "[au]", endDate);

    push({ search: params.toString() });
    onClose();
  };

  const resetStateToUrl = () => {
    setMonth(
      getMonths()[parseInt(params.get("filter[date][du]").substring(5, 7)) - 1]
    );
    setYear(params.get("filter[date][du]").substring(0, 4));
  };

  const handleClose = () => {
    onClose();
    resetStateToUrl();
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      shadow="lg"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          size="xs"
          minW="fit-content"
          variant={params.has(`${id}[du]`) ? "solid" : "outline"}
          colorScheme="twitter"
          leftIcon={leftIcon}
        >
          {params.has(`${id}[du]`)
            ? `${
                getMonths()[
                  parseInt(params.get("filter[date][du]").substring(5, 7)) - 1
                ]
              } ${params.get("filter[date][du]").substring(0, 4)}`
            : label}
        </Button>
      </PopoverTrigger>
      <PopoverContent p={3} shadow="lg">
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />

          <FormLabel>{label}</FormLabel>
          <Stack maxHeight="200px" width="100%">
            <Wrap width="100%" justify="center" align="center">
              {getMonths().map((monthItem, key) => {
                const startDate = `${year}-${(key + 1)
                  .toString()
                  .padStart(2, "0")}-01`;

                return (
                  <WrapItem key={key}>
                    <Button
                      size="xs"
                      colorScheme="twitter"
                      id={monthItem}
                      variant={month === monthItem ? "solid" : "ghost"}
                      onClick={() => setMonth(monthItem)}
                      disabled={dayjs().isBefore(dayjs(startDate))}
                    >
                      {monthItem}
                    </Button>
                  </WrapItem>
                );
              })}
            </Wrap>
            <Divider />
            <Wrap width="100%" justify="center" align="center">
              {getYears().map((yearItem) => {
                return (
                  <WrapItem key={yearItem}>
                    <Button
                      size="xs"
                      colorScheme="twitter"
                      id={yearItem}
                      variant={year === yearItem ? "solid" : "ghost"}
                      onClick={() => setYear(yearItem)}
                      disabled={dayjs().isBefore(dayjs(`${yearItem}-01-01`))}
                    >
                      {yearItem}
                    </Button>
                  </WrapItem>
                );
              })}
            </Wrap>
          </Stack>

          <ButtonGroup display="flex" mt={3} justifyContent="flex-end">
            <Button
              size="sm"
              variant="ghost"
              onClick={handleClose}
              colorScheme="blackAlpha"
            >
              Annuler
            </Button>
            <Button
              size="sm"
              variant="ghost"
              colorScheme="twitter"
              onClick={handleSetParam}
              // Vérification si le choix est bien antérieur à la date actuelle
              disabled={dayjs().isBefore(
                dayjs(
                  `${year}-${(getMonths()?.indexOf(month) + 1)
                    .toString()
                    .padStart(2, "0")}-01`
                )
              )}
            >
              Valider
            </Button>
          </ButtonGroup>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default FilterMonthButton;
