import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";

const BadgeClasse = (props) => {
  const { classe } = props;
  
  return (
    <Box
      paddingX={3}
      paddingY={2}
      borderRadius={7}
      backgroundColor="#8bc34a"
      shadow="md"
    >
      <Stack direction="row" width="100%" justifyContent="space-between" align="center">
        <Text textTransform="uppercase" fontWeight={700} color="#FFF">
          {classe?.nom}
        </Text>
        <Text
          textTransform="uppercase"
          fontWeight={700}
          fontSize={11}
          color="#FFF"
        >
          {classe?.etablissement?.nom === "CE"
            ? "Centre Essalam"
            : classe?.etablissement?.nom}
        </Text>
      </Stack>
    </Box>
  );
};

export default BadgeClasse;
