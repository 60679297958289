import { Stack, Text } from "@chakra-ui/react";
import React from "react";
import NavItem from "./NavItem";

const ListNav = (props) => {
  const { catNav } = props;

  const links = catNav?.links ?? [];
  const isEmptyArrayLinks = links?.find((element) => element?.canAccess);

  if (isEmptyArrayLinks) {
    return (
      <Stack px={4} spacing={1}>
        <Text fontWeight={700} fontSize={11} color="gray.500" ml={2.5}>
          {catNav?.title}
        </Text>
        {links.map((item) => {
          if (item?.canAccess) {
            return <NavItem key={item?.label} navItem={item} />;
          } else {
            return null;
          }
        })}
      </Stack>
    );
  }
};

export default ListNav;
