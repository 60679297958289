import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { Formiz, useForm } from "@formiz/core";
import { isEmail } from "@formiz/validations";
import { FieldInput } from "components/fields/FieldInput";
import React from "react";
import { getQuestions } from "./_partials/QuestionsInscription";
import { useState } from "react";
import { FieldDatePicker } from "components/fields/FieldDatePicker";
import GroupFieldsTitle from "components/fields/GroupFieldsTitle";
import { ReactSelect } from "components/react-select/ReactSelect";
import { useReinscriptionEleve } from "./services/useReinscriptionEleve";
// import { useState } from "react";

const PageFormulaireInscription = () => {
  const formInscriptionEleve = useForm({ subscribe: "form" });

  const { push } = useHistory();

  const [genreChecked, setGenreChecked] = useState();
  const [typeResponsable1Checked, setTypeResponsable1Checked] = useState();
  const [typeResponsable2Checked, setTypeResponsable2Checked] = useState();
  const [etablissementAnterieur, setEtablissementAnterieur] = useState();
  const [classeAnterieure, setClasseAnterieure] = useState();
  const [acceptChecked, setAcceptChecked] = useState();
  const [acceptImageChecked, setAcceptImageChecked] = useState();
  const [adresseIdentique, setAdresseIdentique] = useState(true);

  const { genre, typeResponsable } = getQuestions();

  const handleCallbackSuccess = async ({ inscription }) => {
    push(`/inscription/${inscription.id}`);
  };

  const { mutate: reinscriptionEleve, isLoading: isLoadingReinscriptionEleve } =
    useReinscriptionEleve({
      handleCallbackSuccess,
    });

  const handleSubmit = (values) => {
    const datas = {
      ...values,
      eleve: {
        ...values.eleve,
        genre: genreChecked,
        hasAcceptImage: acceptImageChecked ? acceptImageChecked : false,
      },
      inscription: {
        ...values.inscription,
        typeInscription: "reinscription",
        etablissementAnterieur: etablissementAnterieur,
        classeAnterieure: classeAnterieure,
        hasAccept: acceptChecked,
      },
      responsables: {
        0: {
          ...values.responsables[0],
          genre: typeResponsable1Checked,
        },
        1: {
          ...values.responsables[1],
          genre: typeResponsable2Checked,
          adresse: adresseIdentique
            ? values.responsables[0].adresse
            : values.responsables[1].adresse,
          codePostal: adresseIdentique
            ? values.responsables[0].codePostal
            : values.responsables[1].codePostal,
          ville: adresseIdentique
            ? values.responsables[0].ville
            : values.responsables[1].ville,
        },
      },
    };

    const formData = new FormData();
    formData.append("datas", JSON.stringify(datas));

    if (
      genreChecked !== undefined &&
      typeResponsable1Checked !== undefined &&
      typeResponsable2Checked !== undefined &&
      acceptChecked === true
    ) {
      reinscriptionEleve(formData);
    }
    return null;
  };

  return (
    <HStack
      minH="100vh"
      height="100%"
      maxWidth="100vw"
      width="100%"
      align="flex-start"
      spacing={0}
    >
      <Stack
        w="100%"
        maxW="100vw"
        h="100%"
        minH="100vh"
        spacing={0}
        overflow="hidden"
        position="relative"
        align="center"
      >
        <Stack align="center" maxWidth={{ base: "90vw", md: "40vw" }} pt={50}>
          <Box mb={5}>
            <Image src="/media/logo-amh-vert.jpg" h={50} alt="logo" />
          </Box>
          <Stack pb={5} w="100%">
            <Heading color="black">Réinscription 2023/2024</Heading>
            <Text>
              Merci de remplir ce formulaire afin de réinscrire votre enfant
              pour l'année scolaire 2023/2024.
            </Text>
            <Text>
              Les groupes et plannings sont établis par l'administration et
              seront communiqués avant la rentrée scolaire.
            </Text>
            <Text>
              Les dossiers d'inscription seront traités par ordre d'arrivée.{" "}
              <span style={{ fontWeight: 700 }}>
                Seuls les dossiers complets seront traités.
              </span>
            </Text>
            <Text>Ils doivent comporter impérativement :</Text>
            <Text>
              -{" "}
              <span style={{ fontWeight: 700 }}>
                Le dossier d'inscription dûment rempli et signé
              </span>
            </Text>
            <Text>
              -{" "}
              <span style={{ fontWeight: 700 }}>
                La totalité des frais d'inscription. Le dépôt de tous les
                chèques est obligatoire le jour de l'inscription.
              </span>{" "}
              Pour tout paiement en espèces ou en carte bleue, la totalité de la
              cotisation est exigée le jour de l'inscription)
            </Text>
            <Text>- Attestation responsabilité civile</Text>
            <Text>
              Aucun remboursement ne pourra être effectué une fois le dossier
              validé.
            </Text>
            <Text>
              Les dossiers d'inscriptions complets peuvent être déposés ou
              envoyés par courrier à l'AMH{" "}
              <span style={{ fontWeight: 700 }}>avant le 2 juillet 2023</span>.
            </Text>
          </Stack>

          <Stack>
            <Formiz
              autoform
              connect={formInscriptionEleve}
              onValidSubmit={handleSubmit}
            >
              <Stack spacing={5}>
                {/* DÉBUT DU FORMULAIRE */}

                <GroupFieldsTitle title="Responsables Légaux" />

                {/* REPSONSABLE 1 */}
                <Stack bgColor="rgba(0,0,0,.01)" p={2} borderRadius={12}>
                  <Text
                    textTransform="uppercase"
                    fontWeight={700}
                    fontSize={12}
                  >
                    Responsable n°1
                  </Text>

                  {/* TYPE REPONSABLE */}
                  <Stack spacing={1}>
                    <Text fontSize="md" color="gray.500">
                      Choisir *
                    </Text>
                    <RadioGroup name="responsables.0.genre">
                      <HStack spacing={2}>
                        {typeResponsable?.map((item) => (
                          <Radio
                            key={item?.value}
                            value={item?.value}
                            onChange={(i) =>
                              setTypeResponsable1Checked(i?.target?.value)
                            }
                          >
                            <Text
                              textTransform="uppercase"
                              fontSize="12px"
                              fontWeight={700}
                            >
                              {item?.display}
                            </Text>
                          </Radio>
                        ))}
                      </HStack>
                      {formInscriptionEleve?.isSubmitted &&
                        typeResponsable1Checked === undefined && (
                          <Text color="red" fontWeight="500" fontSize="13px">
                            Veuillez sélectionner au moins une réponse.
                          </Text>
                        )}
                    </RadioGroup>
                  </Stack>

                  <HStack align="flex-start">
                    <FieldInput
                      w="100%"
                      name="responsables.0.nom"
                      label="Nom"
                      size="md"
                      borderRadius="8px"
                      required="Requis"
                      isSubmitted={formInscriptionEleve?.isSubmitted}
                    />
                    <FieldInput
                      w="100%"
                      name="responsables.0.prenom"
                      label="Prénom"
                      size="md"
                      borderRadius="8px"
                      required="Requis"
                      isSubmitted={formInscriptionEleve?.isSubmitted}
                    />
                  </HStack>
                  <FieldInput
                    w="100%"
                    name="responsables.0.adresse"
                    label="N° et nom de rue"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <HStack>
                    <FieldInput
                      w="100%"
                      name="responsables.0.codePostal"
                      type="tel"
                      label="Code Postal"
                      size="md"
                      borderRadius="8px"
                      required="Requis"
                      isSubmitted={formInscriptionEleve?.isSubmitted}
                    />
                    <FieldInput
                      w="100%"
                      name="responsables.0.ville"
                      label="Ville"
                      size="md"
                      borderRadius="8px"
                      required="Requis"
                      isSubmitted={formInscriptionEleve?.isSubmitted}
                    />
                  </HStack>
                  <FieldInput
                    w="100%"
                    name="responsables.0.profession"
                    label="Profession"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    name="responsables.0.email"
                    label="Email"
                    type="email"
                    validations={[
                      {
                        rule: isEmail(),
                        message: "Vérifiez le format de l'email",
                      },
                    ]}
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    name="responsables.0.telephone"
                    type="tel"
                    label="Numéro de téléphone"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                </Stack>

                {/* REPSONSABLE 2*/}
                <Stack bgColor="rgba(0,0,0,.01)" p={2} borderRadius={12}>
                  <Text
                    textTransform="uppercase"
                    fontWeight={700}
                    fontSize={12}
                  >
                    Responsable n°2
                  </Text>

                  {/* TYPE REPONSABLE */}
                  <Stack spacing={1}>
                    <Text fontSize="md" color="gray.500">
                      Choisir *
                    </Text>
                    <RadioGroup name="responsables.1.genre">
                      <HStack spacing={2}>
                        {typeResponsable?.map((item) => (
                          <Radio
                            key={item?.value}
                            value={item?.value}
                            onChange={(i) =>
                              setTypeResponsable2Checked(i?.target?.value)
                            }
                          >
                            <Text
                              textTransform="uppercase"
                              fontSize="12px"
                              fontWeight={700}
                            >
                              {item?.display}
                            </Text>
                          </Radio>
                        ))}
                      </HStack>
                      {formInscriptionEleve?.isSubmitted &&
                        typeResponsable2Checked === undefined && (
                          <Text color="red" fontWeight="500" fontSize="13px">
                            Veuillez sélectionner au moins une réponse.
                          </Text>
                        )}
                    </RadioGroup>
                  </Stack>

                  <HStack align="flex-start">
                    <FieldInput
                      w="100%"
                      name="responsables.1.nom"
                      label="Nom"
                      size="md"
                      borderRadius="8px"
                      required="Requis"
                      isSubmitted={formInscriptionEleve?.isSubmitted}
                    />
                    <FieldInput
                      w="100%"
                      name="responsables.1.prenom"
                      label="Prénom"
                      size="md"
                      borderRadius="8px"
                      required="Requis"
                      isSubmitted={formInscriptionEleve?.isSubmitted}
                    />
                  </HStack>
                  <Checkbox
                    onChange={(i) => {
                      setAdresseIdentique(i?.target?.checked);
                    }}
                    defaultChecked
                  >
                    <Text>Adresse identique au responsable ci-dessus</Text>
                  </Checkbox>
                  {!adresseIdentique ? (
                    <>
                      <FieldInput
                        w="100%"
                        name="responsables.1.adresse"
                        label="N° et nom de rue"
                        size="md"
                        borderRadius="8px"
                        required="Requis"
                        isSubmitted={formInscriptionEleve?.isSubmitted}
                      />
                      <HStack>
                        <FieldInput
                          w="100%"
                          name="responsables.1.codePostal"
                          type="tel"
                          label="Code Postal"
                          size="md"
                          borderRadius="8px"
                          required="Requis"
                          isSubmitted={formInscriptionEleve?.isSubmitted}
                        />
                        <FieldInput
                          w="100%"
                          name="responsables.1.ville"
                          label="Ville"
                          size="md"
                          borderRadius="8px"
                          required="Requis"
                          isSubmitted={formInscriptionEleve?.isSubmitted}
                        />
                      </HStack>
                    </>
                  ) : null}

                  <FieldInput
                    w="100%"
                    name="responsables.1.profession"
                    label="Profession"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    name="responsables.1.email"
                    label="Email"
                    type="email"
                    validations={[
                      {
                        rule: isEmail(),
                        message: "Vérifiez le format de l'email",
                      },
                    ]}
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    name="responsables.1.telephone"
                    type="tel"
                    label="Numéro de téléphone"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                </Stack>

                {/* INSCRIPTION REINSCRIPTION */}
                {/* <Stack spacing={1}>
                  <Text fontSize="md" color="gray.500">
                    Choisir *
                  </Text>
                  <RadioGroup name="eleve.typeInscription">
                    <HStack spacing={2}>
                      {typeInscription?.map((item) => (
                        <Radio
                          key={item?.value}
                          value={item?.value}
                          onChange={(i) =>
                            setInscriptionChecked(i?.target?.value)
                          }
                        >
                          <Text
                            textTransform="uppercase"
                            fontSize="12px"
                            fontWeight={700}
                          >
                            {item?.display}
                          </Text>
                        </Radio>
                      ))}
                    </HStack>
                    {formInscriptionEleve?.isSubmitted &&
                      inscriptionChecked === undefined && (
                        <Text color="red" fontWeight="500" fontSize="13px">
                          Veuillez sélectionner au moins une réponse.
                        </Text>
                      )}
                  </RadioGroup>
                </Stack> */}

                <Stack spacing={1}>
                  <Text fontSize="md" color="gray.500">
                    Genre *
                  </Text>
                  <RadioGroup name="eleve.genre">
                    <HStack spacing={2}>
                      {genre?.map((item) => (
                        <Radio
                          key={item?.value}
                          value={item?.value}
                          onChange={(i) => setGenreChecked(i?.target?.value)}
                        >
                          <Text
                            textTransform="uppercase"
                            fontSize="12px"
                            fontWeight={700}
                          >
                            {item?.display}
                          </Text>
                        </Radio>
                      ))}
                    </HStack>
                    {formInscriptionEleve?.isSubmitted &&
                      genreChecked === undefined && (
                        <Text color="red" fontWeight="500" fontSize="13px">
                          Veuillez sélectionner au moins une réponse.
                        </Text>
                      )}
                  </RadioGroup>
                </Stack>
                <HStack align="center">
                  <Box width="100%">
                    <ReactSelect
                      callback={(e) => setEtablissementAnterieur(e?.value)}
                      name="inscription.etablissementAnterieur"
                      size="md"
                      label="Établ. 2022-2023"
                      className="basic-single"
                      placeholder="Sélectionner..."
                      options={[
                        { label: "Centre Essalam", value: "CE" },
                        { label: "CIH", value: "CIH" },
                      ]}
                      required
                      isClearable
                    />
                  </Box>
                  <Box width="100%">
                    <ReactSelect
                      callback={(e) => setClasseAnterieure(e?.value)}
                      name="inscription.classeAnterieure"
                      size="md"
                      label="Classe 2022-2023"
                      className="basic-single"
                      placeholder="Sélectionner..."
                      options={[
                        { label: "Mini Club 1", value: "Mini Club 1" },
                        { label: "Mini Club 2", value: "Mini Club 2" },
                        { label: "CP A", value: "CP A" },
                        { label: "CP B", value: "CP B" },
                        { label: "CP C", value: "CP C" },
                        { label: "N Préparatoire", value: "N Préparatoire" },
                        { label: "N1 A", value: "N1 A" },
                        { label: "N1 B", value: "N1 B" },
                        { label: "N1 C", value: "N1 C" },
                        { label: "N1 D", value: "N1 D" },
                        { label: "N2 A", value: "N2 A" },
                        { label: "N2 B", value: "N2 B" },
                        { label: "N2 C", value: "N2 C" },
                        { label: "N3", value: "N3" },
                        { label: "N4", value: "N4" },
                        { label: "N5", value: "N5" },
                        { label: "N6", value: "N6" },
                        {
                          label: "N Jeune Débutant",
                          value: "N Jeune Débutant",
                        },
                        { label: "N Jeune 1", value: "N Jeune 1" },
                        { label: "N Jeune 2", value: "N Jeune 2" },
                      ]}
                      required
                      isClearable
                    />
                  </Box>
                </HStack>

                <HStack align="flex-start">
                  <FieldInput
                    w="100%"
                    name="eleve.nom"
                    label="Nom"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                  <FieldInput
                    w="100%"
                    name="eleve.prenom"
                    label="Prénom"
                    size="md"
                    borderRadius="8px"
                    required="Requis"
                    isSubmitted={formInscriptionEleve?.isSubmitted}
                  />
                </HStack>
                <FieldDatePicker
                  w="100%"
                  name="eleve.dateNaissance"
                  label="Date de naissance"
                  required="Requis"
                  size="md"
                  isSubmitted={formInscriptionEleve?.isSubmitted}
                />
              </Stack>

              <Stack py={3}>
                <Checkbox
                  name="accept"
                  alignItems="flex-start"
                  onChange={(i) => {
                    setAcceptImageChecked(i?.target?.checked);
                  }}
                >
                  J'autorise mon enfant a être photographié(e) lors des photos
                  de classes et/ou des reportages photos et/ou vidéos effectués
                  par l'AMH
                </Checkbox>
                <Checkbox
                  name="accept"
                  color={
                    formInscriptionEleve?.isSubmitted &&
                    acceptChecked === undefined
                      ? "red"
                      : null
                  }
                  alignItems="flex-start"
                  onChange={(i) => {
                    setAcceptChecked(i?.target?.checked);
                  }}
                >
                  J'ai lu et accepté les modalités d'inscription *
                </Checkbox>
              </Stack>
            </Formiz>
          </Stack>
          <Box pt={5} pb={100} w="100%">
            <Button
              background="#469BDD"
              isLoading={isLoadingReinscriptionEleve}
              w="100%"
              color="white"
              borderRadius="8px"
              onClick={formInscriptionEleve.submit}
              py="16px"
              _hover={{
                background: "#548FF2",
              }}
              _active={{
                background: "#548FF2",
              }}
            >
              ENVOYER LA DEMANDE
            </Button>
          </Box>
          {/* <Modal isOpen={isOpenModalCharte} onClose={onCloseModalCharte}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Charte AMH</ModalHeader>
              <ModalCloseButton />
              <ModalBody></ModalBody>

              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={onCloseModalCharte}>
                  Je refuse
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => {
                    setAcceptChecked(true);
                    onCloseModalCharte();
                  }}
                >
                  J'accepte
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal> */}
        </Stack>
      </Stack>
    </HStack>
  );
};

export default PageFormulaireInscription;
