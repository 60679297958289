import { Skeleton, Stack } from "@chakra-ui/react";
import EleveList from "App/eleve/components/EleveList";
import { LayoutDefault } from "App/layout/LayoutDefault";
import React from "react";
import {
  // useHistory,
  useParams,
} from "react-router-dom";
import BadgeClasse from "../components/BadgeClasse";
import { useFindClasseById } from "../services/useFindClasseById";

const PageClasse = () => {
  const { classeId } = useParams();

  const {
    classe,
    canCud,
    isLoading: isLoadingClasse,
    // isFetching: isFetchingClasse,
  } = useFindClasseById(classeId);

  // const { push } = useHistory();

  return (
    <LayoutDefault>
      <BadgeClasse classe={classe} />

      <Stack paddingX={5} paddingY={3} spacing={5}>
        {isLoadingClasse && <Skeleton height="50px" />}

        {!isLoadingClasse && <EleveList classeId={classeId} canCud={canCud} />}
      </Stack>
    </LayoutDefault>
  );
};

export default PageClasse;
