import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * Return Eleves
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindDashboardAccueil = () => {
  const { data, isLoading } = useQuery("dashboard-accueil", () => {
    return Axios.get("/dashboard/accueil");
  });

  const { countEleves, countClasses } = data ?? [];

  return {
    countEleves,
    countClasses,
    isLoading,
  };
};
