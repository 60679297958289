import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindUtilisateurByFilters = (params) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    ["utilisateur", params?.toString()],
    () => {
      return Axios.get(`/utilisateurs`, { params });
    },
    { retry: false, refetchOnWindowFocus: true,}
  );

  const { utilisateur } = data ?? [];

  return {
    utilisateur,
    isLoading: isLoading || isFetching,
    isError,
  };
};
