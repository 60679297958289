import React, { useState } from "react";
import { useField } from "@formiz/core";
import {
  Stack,
  Input,
  Text,
  InputGroup,
  InputRightElement,
  Button,
  Icon,
  Badge,
} from "@chakra-ui/react";
import {
  AiFillEyeInvisible as HideIcon,
  AiFillEye as ShowIcon,
} from "react-icons/ai";

export const FieldPassword = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } =
    useField(props);
  const { label, required } = props;
  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Stack mb={30} spacing={0}>
      <label htmlFor={id}>
        <Text fontSize={14} fontWeight={600} color="#334155" mb="8px">
          {label}
            {!!required && <Badge colorScheme="brand" ml={1} px={2} py="2px" borderRadius="6px" textTransform="lowercase">Requis</Badge>}
        </Text>
      </label>
      <InputGroup>
        <Input
          id={id}
          type={show ? "text" : "password"}
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => setIsTouched(true)}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          color="#475569"
          size="lg"
          borderColor="#cbd5e1"
          fontSize="14px"
          lineHeight="20px"
          placeholder="Mot de passe"
          _placeholder={{
            fontWeight: 500,
            color: "#444b54",
          }}
        />
        <InputRightElement width="4.5rem">
          <Button h="2rem" size="sm" onClick={handleClick}>
            {show ? <Icon as={ShowIcon} /> : <Icon as={HideIcon} />}
          </Button>
        </InputRightElement>
      </InputGroup>
      {showError && (
        <Text color="red" fontWeight="500" fontSize="13px" id={`${id}-error`}>
          {errorMessage}
        </Text>
      )}
    </Stack>
  );
};
