import { useQuery } from "react-query";
import Axios from "config/axios";

/**
 * @author <issadricipro@gmail.com>
 *
 */
export const useFindEleveResponsableLegauxByFilters = (params) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    ["eleveResponsableLegaux", params?.toString()],
    () => {
      return Axios.get(`/eleveResponsableLegaux`, { params });
    },
    { retry: false, refetchOnWindowFocus: true }
  );

  const { eleveResponsableLegaux } = data ?? [];

  return {
    eleveResponsableLegaux,
    isLoading: isLoading || isFetching,
    isError,
  };
};
