import {
  Avatar,
  Button,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useAuthentificationContext } from "context/auth-context";
import React from "react";
import { useHistory } from "react-router-dom";

export const ButtonProfil = () => {
  const { user } = useAuthentificationContext();
  const { push } = useHistory();
  return (
    <HStack>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Avatar
            size="sm"
            name={`${user?.prenom} ${user?.nom}`}
            bgColor="brand.500"
            cursor="pointer"
            color="brand.700"
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody shadow="lg" borderRadius="md">
            <Stack
              color="#000"
              width="100%"
              align="center"
              pt={3}
              pb={1}
              justify="center"
              spacing={4}
            >
              <Avatar size="md" name={`${user?.prenom} ${user?.nom}`} />
              <Stack spacing={0} width="100%" align="center" justify="center">
                <Heading size="md">{`${user?.prenom} ${user?.nom}`}</Heading>
                <Text size="md">{`${user?.email}`}</Text>
              </Stack>
              <Button
                colorScheme="red"
                w="100%"
                onClick={() => push("/logout")}
              >
                Déconnexion
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {/* <Heading size="sm" color="brand.grisTexte">{`${user?.prenom} ${user?.nom}`}</Heading> */}
    </HStack>
  );
};
