import React from "react";
import { useField } from "@formiz/core";
import { Stack, Input, Text, InputGroup, Badge } from "@chakra-ui/react";

export const FieldInput = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } =
    useField(props);
  const {
    label,
    type,
    required,
    mb,
    size,
    disabled,
    placeholder,
    textAlign,
    inputLeftElement,
    variant,
    ...rest
  } = props;
  const [isTouched, setIsTouched] = React.useState(false);

  const showError = !isValid && (isTouched || isSubmitted);

  return (
    <Stack mb={mb} spacing={0} {...rest}>
      {label ? (
        <label htmlFor={id}>
          <Text fontSize={14} fontWeight={600} color="#334155" mb="8px">
            {label}
            {!!required && (
              <Badge
                colorScheme="brand"
                ml={1}
                px={2}
                py="2px"
                borderRadius="6px"
                textTransform="lowercase"
              >
                Requis
              </Badge>
            )}
          </Text>
        </label>
      ) : null}

      <InputGroup>
        {inputLeftElement ? inputLeftElement : null}
        <Input
          id={id}
          type={type || "text"}
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => setIsTouched(true)}
          disabled={disabled}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          borderColor={showError ? "red" : "#cbd5e1"}
          // size={size ?? "sm"}
          size={size ? size : "lg"}
          placeholder={placeholder}
          textAlign={textAlign}
          color="#475569"
          fontSize="14px"
          fontWeight={400}
          fontFamily="'Inter', sans-serif"
          lineHeight="20px"
          variant={variant ? variant : null}
          _placeholder={{
            fontWeight: 500,
            color: "#8895a4",
          }}
        />
      </InputGroup>
      {showError && (
        <Text color="red" fontWeight="500" fontSize="13px" id={`${id}-error`}>
          {errorMessage}
        </Text>
      )}
    </Stack>
  );
};
