import { Stack } from "@chakra-ui/react";
import { FieldDatePicker } from "components/fields/FieldDatePicker";
import { FieldInput } from "components/fields/FieldInput";
// import { ReactSelect } from "components/react-select/ReactSelect";
import React from "react";

const FormulaireEleve = () => {
  // const {
  //   classes,
  //   anneesScolaire,
  //   classeEleveId,
  //   setClasseId,
  //   setAnneeScolaireId,
  // } = props;

  // const classeCE = classes?.CE?.map((item) => ({
  //   value: item?.id,
  //   label: `${item?.etablissement?.nom} - ${item?.nom}`,
  // }));

  // const classeCIH = classes?.CIH?.map((item) => ({
  //   value: item?.id,
  //   label: `${item?.etablissement?.nom} - ${item?.nom}`,
  // }));

  // const optionsClasses = [...classeCE, ...classeCIH];
  // const optionsAnneesScolaire = anneesScolaire?.map((annee) => ({
  //   value: annee?.id,
  //   label: annee?.label,
  // }));

  return (
    <Stack>
      <Stack direction={{ base: "column", sm: "row" }} alignItems="flex-start">
        <FieldInput
          w="100%"
          // defaultValue={defaultValue}
          name="nom"
          label="Nom"
          required="Requis"
          size="md"
        />
        <FieldInput
          w="100%"
          // defaultValue={defaultValue}
          name="prenom"
          label="Prenom"
          required="Requis"
          size="md"
        />
      </Stack>
      <Stack direction={{ base: "column", sm: "row" }} alignItems="flex-start">
        <FieldDatePicker
          w="100%"
          // defaultValue={defaultValue}
          name="dateNaissance"
          label="Date de naissance"
          required="Requis"
          size="md"
        />
        <FieldInput
          w="100%"
          // defaultValue={defaultValue}
          name="telephone"
          label="Téléphone"
          // required="Requis"
          size="md"
        />
      </Stack>
      <FieldInput
        w="100%"
        // defaultValue={defaultValue}
        name="adresse"
        label="Adresse Postale"
        // required="Requis"
        size="md"
      />
      <FieldInput
        w="100%"
        // defaultValue={defaultValue}
        name="mail"
        label="Adresse mail"
        // required="Requis"
        size="md"
      />
      {/* <ReactSelect
        callback={(e) => setClasseId(e?.value)}
        defaultValue={
          classeEleveId
            ? optionsClasses?.filter(
                (item) => item?.value === parseInt(classeEleveId)
              )
            : null
        }
        name="classe"
        label="Classe"
        className="basic-single"
        placeholder="Sélectionner..."
        options={optionsClasses}
        isClearable
        size="md"
      />
      <ReactSelect
        callback={(e) => setAnneeScolaireId(e?.value)}
        defaultValue={
          classeEleveId
            ? optionsClasses?.filter(
                (item) => item?.value === parseInt(classeEleveId)
              )
            : null
        }
        name="anneeScolaire"
        label="Année Scolaire"
        className="basic-single"
        placeholder="Sélectionner..."
        options={optionsAnneesScolaire}
        isClearable
        size="md"
      /> */}
    </Stack>
  );
};

export default FormulaireEleve;
